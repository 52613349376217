<div class="assets_card" data-test-asset-card>
  <div *ngIf="asset.canDownloadOriginal === false" class="assets_card-disabled-overlay" data-test-asset-card-disabled-overlay></div>
  <div class="assets_card-preview" data-test-asset-card-preview>
    <preview-item [previewUrl]="asset?.filePreviewUrl | async" [fileExtension]="asset?.fileExtension" [fileCategory]="fileCategory"></preview-item>
    <div *ngIf="asset?.fileExtension as fileExtension" class="assets_card-extension" data-test-asset-card-extension>
      {{ fileExtension }}
    </div>
  </div>
  <div *ngIf="asset.canDownloadOriginal === false" class="assets_card-unavailable" data-test-asset-card-unavailable-indicator>
    <celum-icon class="assets_card-unavailable-icon" [configuration]="unavailableIcon"></celum-icon>
    <div class="assets_card-unavailable-text">{{ 'LIBRARIES.CREATE.ASSETS.UNAVAILABLE_INDICATOR' | translate }}</div>
  </div>
  <div class="assets_card-name-wrapper">
    <span class="assets_card-name-wrapper-text" data-test-asset-card-name spaceAwareTooltip [spaceAwareDebounceTime]="200" [matTooltip]="asset?.fileName">
      {{ asset?.fileName }}
    </span>
  </div>
</div>
