import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule } from '@celum/common-components';
import { TranslationModule } from '@celum/ng2base';

import { CelumTree } from './components/celum-tree';
import { CelumTreeNode } from './components/celum-tree-node';
import { treeReducer } from './store/tree-reducers';

export const storeModule = StoreModule.forFeature('tree', treeReducer);

/**
 * ⚠ Requires dependency `@ngrx/store`.
 */
@NgModule({
            imports: [
              // basic angular modules
              CommonModule, storeModule, TranslateModule,
              // needed material modules
              MatTreeModule, MatIconModule, MatProgressBarModule, MatButtonModule, MatProgressSpinnerModule, MatTooltipModule,
              // our own modules
              CelumIconModule, CelumButtonModule, TranslationModule
            ],
            declarations: [
              CelumTree, CelumTreeNode
            ],
            exports: [CelumTree, CelumTreeNode, TranslateModule]
          })
export class CelumTreeModule {
}
