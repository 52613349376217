import { IconConfiguration } from '../icon/icon-configuration';

export class EmptyPageConfig {
  constructor(
    public id: string,
    public iconConfiguration: IconConfiguration,
    public message: string,
    public textSize: 'small' | 'normal',
    public actionText?: string,
    public actionVisible: boolean = false,
    public actionType: 'button' | 'link' = 'button',
    public callback?: (args?: any) => any,
    public actionIcon?: IconConfiguration,
    public maxWidth: string = '32rem'
  ) {}
}
