import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatCheckboxDefaultOptions, MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';

import {
  CelumEmptyPageModule,
  CelumIconModule,
  CelumLoadingMaskModule,
  CelumLookupAreaModule,
  EmptyPage,
  EmptyPageConfig,
  LookupArea
} from '@celum/common-components';
import { isArrayEqual } from '@celum/core';
import { CelumListModule, ListSelectionHandler, SelectionBehavior } from '@celum/internal-components';
import { CelumPipesModule } from '@celum/ng2base';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatTooltipModule,
    TranslateModule,
    CelumIconModule,
    CelumEmptyPageModule,
    CelumLookupAreaModule,
    CelumLoadingMaskModule,
    CelumListModule,
    CelumPipesModule
  ],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
    ListSelectionHandler.withConfiguration({ updateSelectionOnItemsChange: false, selectionBehaviour: SelectionBehavior.MULTI_TOGGLE })
  ],
  selector: 'celum-search-and-select',
  templateUrl: './search-and-select.component.html',
  styleUrls: ['./search-and-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SearchAndSelectComponent<T extends { id: any }> implements OnChanges, OnInit {
  @HostBinding('class.search-and-select') public class = true;

  /** The items to show in the list. */
  @Input() public items: T[] = [];
  /** The currently selected items. */
  @Input() public selectedItems: T[] = [];
  /** Define whether loading should be shown. Default: `true` */
  @Input() public loadingItems = true;
  /** Define whether loading more should be shown. Default: `false` */
  @Input() public loadingMoreItems = false;
  /** Whether the list allows for multiple selection - will show check boxes for the items. Default: `true` */
  @Input() public multipleSelection = true;
  /** Define the icon to use if {@link loadingItems} is `false` and the {@link items} array is empty. Default: `'empty-list'` */
  @Input() public noItemFoundIcon = 'empty-list';
  /** Define the icon size to use if {@link loadingItems} is `false` and the {@link items} array is empty. Default: `72.5` */
  @Input() public noItemFoundIconSize = 72.5;
  /** Define the icon color to use if {@link loadingItems} is `false` and the {@link items} array is empty. */
  @Input() public noItemFoundIconColor: string;
  /** Define the text to show if {@link loadingItems} is `false` and the {@link items} array is empty. Default: `'SHARED.NO_ITEMS_FOUND'` */
  @Input() public noItemText = 'SHARED.NO_ITEMS_FOUND';
  /** Define the placeholder text for the search input. Default: `'SHARED.SEARCH_PLACEHOLDER'` */
  @Input() public searchBoxPlaceholderText = 'SHARED.SEARCH_PLACEHOLDER';
  /** Specify if there are more items to show. Default: `false` */
  @Input() public hasMoreBottom = false;
  /** Define the debounceTime for the search input. Default: `0` */
  @Input() public searchDebounceTime = 0;
  /** Set a template reference for displaying the items in the list. */
  @Input() public itemTemplate: TemplateRef<any>;
  /** Define whether the search box should be hidden. Default: `false` */
  @Input() public hideSearchBox = false;
  /** Define initial search value */
  @Input() public searchValue = '';
  /** Define maximum selectable amount of items */
  @Input() public maxSelectableItems: number = undefined;
  /** Define tooltip for disabled checkbox */
  @Input() public disabledCheckboxTooltip: string;
  /** Define template to show at the bottom of the list */
  @Input() public bottomItem: TemplateRef<any>;
  /** Define if the selection should be cleared on clicking empty area */
  @Input() public clearSelectionOnEmptyClick = false;
  /** Allows to optionally override the scrollengine of the list of displayed results. Default: `'perfect-scrollbar'` */
  @Input() public scrollEngine: 'native' | 'perfect-scrollbar' = 'perfect-scrollbar';

  /** Emits when the search value changes. */
  @Output() public readonly searchValueChanged = new EventEmitter<string>();
  /** Emits when the user has scrolled up or down (`false` and `true`, respectively). Only if {@link multipleSelection} is `true`. */
  @Output() public readonly requestNextPage = new EventEmitter<boolean>();
  /** Emits on changed selection. Also for single selection use. */
  @Output() public readonly itemSelectionChanged = new EventEmitter<T[]>();

  @ViewChild(LookupArea, { static: false, read: LookupArea }) private lookupArea: LookupArea;

  constructor(protected listSelectionHandler: ListSelectionHandler<T>) {}
  /** Optionally define a compare function that is used in the list. By default, ids are compared (if E provides "id" property, or elements itself are compared). */
  @Input() public compareFn: (a: T, b: T) => boolean = (a, b) => a.id === b.id;

  public ngOnInit(): void {
    this.listSelectionHandler.setCompareFn(this.compareFn);

    this.listSelectionHandler.selectionChanged$
      .pipe(filter(selection => !isArrayEqual(this.selectedItems, selection)))
      .subscribe(selection => this.itemSelectionChanged.emit(selection));
  }

  public ngOnChanges({ selectedItems, items, multipleSelection }: SimpleChanges): void {
    if (selectedItems && !isArrayEqual(selectedItems.currentValue, selectedItems.previousValue)) {
      this.listSelectionHandler.setSelection(this.selectedItems);
    }

    if (items?.currentValue?.length > 0) {
      this.lookupArea?.focus();
    }

    if (multipleSelection) {
      if (this.maxSelectableItems && this.maxSelectableItems >= 0) {
        this.listSelectionHandler.setSelectionBehavior(SelectionBehavior.MULTI_TOGGLE_LIMITED);
        this.listSelectionHandler.setSelectionLimit(this.maxSelectableItems);
      } else {
        this.listSelectionHandler.setSelectionBehavior(this.multipleSelection ? SelectionBehavior.MULTI_TOGGLE : SelectionBehavior.SINGLE);
      }
    }
  }

  protected getEmptyPageConfig(noItemFoundIcon: string, noItemText: string, noItemFoundIconSize: number, noItemFoundIconColor: string): EmptyPageConfig {
    const page = EmptyPage.noActionConfig(noItemFoundIcon, noItemFoundIcon, noItemText, 'small', noItemFoundIconSize);
    if (noItemFoundIconColor) {
      page.iconConfiguration.withColor(noItemFoundIconColor);
    }
    return page;
  }
}
