<div *ngIf="scrollEngine==='perfect-scrollbar'" class='dialog-content_scrolling-container'>
  <perfect-scrollbar class='celum-scrollbar dialog-content_scrollable'>
    <ng-container *ngTemplateOutlet='content'></ng-container>
  </perfect-scrollbar>
</div>

<div *ngIf="scrollEngine==='native'" class='celum-scrollbar dialog-content_native-scrolling-container'>
  <ng-container *ngTemplateOutlet='content'></ng-container>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
