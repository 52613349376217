import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, switchMap, tap } from 'rxjs';

import { AtLeastOne, pick } from '@celum/core';
import { LibrariesProperties, LibraryDto } from '@celum/libraries/domain';
import { EntityActions, EntityResolver, ResolverCallConfig, Result, ResultConsumerService, schemaOf } from '@celum/ng2base';
import { ResourceService } from '@celum/shared/domain';

import { translateLibraryPaginationResult } from './library-paginator';
import { LibraryCreator, LibraryEditor, LibraryFindOneOptions, LibraryFindOptions, LibraryStatusUpdater } from './library-service.model';
import { LIBRARY, Library } from './library.entity';

@Injectable({ providedIn: 'root' })
export class LibraryService implements ResourceService<Library>, EntityResolver {
  private readonly librariesMetaInfo = {
    schema: [schemaOf(LIBRARY)],
    resultKey: `content`,
    // specify explicitly as other applications (experience) call this method and do not have the same injection token for pagination
    paginationTranslator: translateLibraryPaginationResult
  };

  constructor(
    private http: HttpClient,
    private consumer: ResultConsumerService,
    private store: Store
  ) {}

  /* The library-BE has a versioned branch (libraries) and an un-versioned one. In order to keep the ServiceAccessToken configuration simple, the main api url
   is without version, and services that require a version should append it locally */
  private get baseUrl(): string {
    return `${LibrariesProperties.properties.apiUrl}/v1/libraries`;
  }

  public create(creator: LibraryCreator): Observable<Library> {
    return this.http.post(this.baseUrl, creator, { observe: 'response' }).pipe(switchMap(response => this.findOne({ id: response.headers.get('Location') })));
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(tap(() => this.store.dispatch(EntityActions.remove({ id }))));
  }

  public find(options?: LibraryFindOptions): Observable<Result<Library>> {
    const params = pick(options, ['page', 'size', 'status', 'createdBy', 'sort', 'nameStartsWith', 'idIn']);
    const response$ = this.http.get<LibraryDto>(this.baseUrl, { params }).pipe(
      map((response: any) => ({
        ...response,
        content: response.content.filter((library: Library) => !options?.status || library.status === options.status)
      })),
      map(this.typekeyMapper)
    );

    return this.consumer.consume<Library>(response$, this.librariesMetaInfo);
  }

  public findOne(options: AtLeastOne<LibraryFindOneOptions>): Observable<Library> {
    const response$ = this.http.get<LibraryDto>(`${this.baseUrl}/${options.id}`).pipe(
      // TODO: Remove after typekey was added to DTO in BE
      map(library => ({
        ...library,
        typeKey: LIBRARY
      }))
    );

    return this.consumer.consume<Library>(response$, { schema: schemaOf(LIBRARY) }).pipe(
      map(value => {
        if (value.entities.length === 1) {
          return value.entities[0];
        } else {
          throw new Error(`LibraryService: findOne resulted in ${value.entities.length} entities when exactly one was expected.`);
        }
      })
    );
  }

  public getMany(ids: string[]): ResolverCallConfig {
    const params = { idIn: ids };
    const response$ = this.http.get(this.baseUrl, { params }).pipe(map(this.typekeyMapper));

    return {
      response$,
      metaInfo: this.librariesMetaInfo
    };
  }

  public update(id: string, updater: LibraryEditor | LibraryStatusUpdater): Observable<Library> {
    const response$ = this.http.patch<Library>(`${this.baseUrl}/${id}`, updater).pipe(
      // TODO: Remove after typekey was added to DTO in BE
      map(library => ({
        ...library,
        typeKey: LIBRARY
      }))
    );
    return this.consumer.consume<Library>(response$, { schema: schemaOf(LIBRARY) }).pipe(map(({ entities }) => entities[0]));
  }

  /**
   * TODO: Remove after typekey was added to DTO in BE
   */
  private typekeyMapper: (response: any) => Observable<Result<Library>> = (response: any) => ({
    ...response,
    content: response.content.map((library: Library) => ({
      ...library,
      typeKey: LIBRARY
    }))
  });
}
