import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { pick } from 'lodash-es';
import { combineLatest, distinctUntilChanged } from 'rxjs';

import { AccountAccess, UserDetails, UserService } from '@celum/authentication';
import { isEqualSimpleObject, isTruthy } from '@celum/core';
import { LibraryFilter } from '@celum/libraries/features/dashboard';
import { LibraryStorageService, LibraryStorageServiceState, StorageLimitStatus } from '@celum/libraries/shared';
import { AccountUser } from '@celum/shared/domain';

import { LOCAL_STORAGE_LIBRARIES_FILTER_KEY } from './dashboard.component';
import { LibraryCountService, LibraryCountState } from './library-count.service';

interface DashboardViewModel {
  totalNumberOfLibraries: number;
  totalNumberOfOwnLibraries: number;
  storageState: StorageLimitStatus;
  isOwner: boolean;
  owner: Partial<AccountUser>;
  filters: LibraryFilter[];
}

interface DashboardState {
  filters: LibraryFilter[];
}

@Injectable({ providedIn: 'root' })
export class DashboardService extends ComponentStore<DashboardState> {
  public vm$ = combineLatest(
    [this.state$, this.libraryStorageService.vm$, this.userService.userInfo$.pipe(isTruthy()), this.libraryCountService.vm$],
    (state, libraryStorageServiceViewModel, currentUser: { user: UserDetails; tenant: AccountAccess }, libraryCount: LibraryCountState) =>
      this.createViewModel(state, libraryStorageServiceViewModel, currentUser, libraryCount)
  );

  constructor(
    private libraryStorageService: LibraryStorageService,
    private libraryCountService: LibraryCountService,
    private userService: UserService
  ) {
    super({
      filters: JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIBRARIES_FILTER_KEY)) ?? []
    });

    this.select(state => pick(state, ['filters']))
      .pipe(distinctUntilChanged(isEqualSimpleObject))
      .subscribe(data => localStorage.setItem(LOCAL_STORAGE_LIBRARIES_FILTER_KEY, JSON.stringify(data.filters)));
  }

  public init(): void {
    this.libraryCountService.loadTotalNumberOfOwnLibraries();
  }

  private createViewModel(
    state: DashboardState,
    libraryStorageServiceState: LibraryStorageServiceState,
    userInfo: { user: UserDetails; tenant: AccountAccess },
    libraryCount: LibraryCountState
  ): DashboardViewModel {
    return {
      totalNumberOfLibraries: libraryCount.totalNumberOfLibraries,
      totalNumberOfOwnLibraries: libraryCount.totalNumberOfOwnLibraries,
      isOwner: userInfo.user.oid === userInfo.tenant?.ownerOid,
      storageState: libraryStorageServiceState.storageLimitStatus,
      owner: {
        id: userInfo.tenant?.ownerOid,
        firstName: userInfo.tenant?.ownerName.split(' ')[0],
        lastName: userInfo.tenant?.ownerName.split(' ')[1],
        email: userInfo.tenant?.ownerEmail,
        profilePictureDownloadLink: userInfo.tenant?.profilePictureDownloadLink
      },
      filters: state.filters
    };
  }
}
