import { createReducer, on } from '@ngrx/store';

import { hasValueChanged, mergeDeep } from '@celum/core';

import { Entity } from '../entity/entity';
import { EntityRegistrationDeviations, EntityRegistry, UpdateStrategy, UpdateStrategyDeviation } from '../entity/entity-registry';
import { deleteEntities, updateEntitiesByTypeRecord, updateEntityAttributes } from '../state/entities/entity-store-utils';
import { EntityActions, InternalEntityActions } from './entity-actions';
import { EntityState } from './entity-state';
import { overwrite } from './entity-update-strategies';

const initialState: EntityState = {
  entitiesById: {},
  entitiesByType: {}
};

export const entityReducer = createReducer(
  initialState,
  on(InternalEntityActions._UpsertMany, (state, { entities, deviations }) => upsertEntities(state, entities, deviations)),
  on(EntityActions.remove, (state, { id }) => deleteEntities(state, [id])),
  on(EntityActions.removeMany, (state, { ids }) => deleteEntities(state, ids))
);

function upsertEntities(state: EntityState, entities: Entity[], deviations?: EntityRegistrationDeviations): EntityState {
  const clone = cloneState(state);
  const hasChanges = entities.reduce((changed, entity) => upsertEntity(clone, entity, deviations) || changed, false);
  updateEntitiesByTypeRecord(entities, clone.entitiesByType);
  return hasChanges ? clone : state;
}

function upsertEntity(state: EntityState, entity: Entity, deviations?: EntityRegistrationDeviations): boolean {
  const byId = state.entitiesById;

  if (!byId[entity.id]) {
    byId[entity.id] = { ...entity };
    return true;
  }

  const oldEntity = byId[entity.id];
  const clonedOldEntity = { ...oldEntity };  // create from old entity to prevent as many unnecessary changes as possible!

  const strategy = EntityRegistry.get(entity.typeKey).updateStrategy ?? overwrite;
  const deviation = deviations?.[entity.typeKey]?.updateStrategy;
  const { result, hasChanges } = updateEntity(clonedOldEntity, entity, strategy, deviation);

  if (hasChanges) {
    byId[entity.id] = result;
  }

  return hasChanges;
}

function cloneState(state: EntityState): EntityState {
  return {
    entitiesByType: { ...state.entitiesByType },
    entitiesById: { ...state.entitiesById }
  };
}

/**
 * Update an entity considering the default {@param strategy} and {@param deviation deviations} from it
 * @param clone old entity which will be updated
 * @param next new entity coming in
 * @param strategy default strategy for the given entity type
 * @param deviation the deviation of the default strategy
 * @return resultObject containing the updated entity and if it has actually changed
 */
export function updateEntity(clone: Entity, next: Entity, strategy: UpdateStrategy<any>,
                             deviation: UpdateStrategyDeviation<any>): { result: Entity, hasChanges: boolean } {

  if (!deviation) {
    fillStrategyWithDefaults(clone, strategy);
    return updateEntityAttributes(next, clone, strategy);
  }

  if (typeof deviation === 'function') {
    return updateEntityAttributes(next, clone, deviation);
  }

  const { inheritStrategy, ...deviationStrategy } = deviation;
  if (!inheritStrategy) {
    // if no strategy is inherited, then we can completely dismiss the given strategy and only use the deviation instead
    fillStrategyWithDefaults(clone, deviationStrategy);
    return updateEntityAttributes(next, clone, deviationStrategy);
  }

  fillStrategyWithDefaults(clone, strategy);

  // step 1 - update entity with the default strategy
  const baseResult = updateEntityAttributes(next, { ...clone }, strategy);
  // step 2 - update entity with deviations (we are interested in the strategyResult)
  const deviationResult = updateEntityAttributes(next, { ...clone }, deviationStrategy ?? overwrite);
  // step 3 - take the entity from step 1 and overwrite it with all values from step 2
  const result = mergeDeep(baseResult.result, deviationResult.strategyResult);

  const hasChanges = hasValueChanged(clone, result);
  return { result: hasChanges ? result : clone, hasChanges };
}

/** Iterate over all properties from the given value and apply a default strategy for all which are not specifically mentioned in the given strategy */
function fillStrategyWithDefaults<T>(value: T, strategy: UpdateStrategy<any>): void {
  if (typeof strategy === 'object') {
    return Object.keys(value).filter(prop => !strategy[prop]).forEach(prop => strategy[prop] = overwrite);
  }
}
