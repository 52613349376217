import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { startWith, takeUntil } from 'rxjs';

import { isEqualSimpleObject } from '@celum/core';
import { CelumValidators, RouteParamService } from '@celum/ng2base';
import { DesignerEditMode, DesignerServiceViewModel, WizardStepStatus } from '@celum/shared/domain';

export const WIZARD_URL_PARAM = `wizard`;

export interface DesignerStoreState {
  activeStepIndex: number;
  stepStatus: WizardStepStatus[];
  designerEditMode: DesignerEditMode;
}

/**
 * Used for FormBased wizards (that are currently called Designers).
 * (note: this is not a generic wizard service, but a service for the designer components that work with forms)
 */
@Injectable()
export class DesignerService extends ComponentStore<DesignerStoreState> {
  /* The view model for the service */
  public vm$ = this.select(state => this.createViewModel(state));

  /* The form group to update by the service. Must contain form groups for each step */
  public form = new FormGroup<any>({});

  private initialValue: unknown;

  private itemsSameFn: (obj1: any, obj2: any) => boolean;

  constructor(
    public dialogRef: MatDialogRef<void, boolean>,
    private routeParamService: RouteParamService
  ) {
    super({
      activeStepIndex: 0,
      stepStatus: [],
      designerEditMode: DesignerEditMode.CREATE
    });

    dialogRef.afterClosed().subscribe(() => routeParamService.setTemporaryParam(WIZARD_URL_PARAM, null));
  }

  /**
   * Service initialiser function
   * @param form The form group to initialize the service with. Must contain form groups for each step.
   * @param designerEditMode specifies whether designer is opened in create or edit mode. Create mode is by default.
   * @param itemsSameFn optional param to be used for checking whether form has changed
   */
  public initializeDesigner(
    form: FormGroup,
    designerEditMode: DesignerEditMode = DesignerEditMode.CREATE,
    itemsSameFn?: (obj1: any, obj2: any) => boolean
  ): void {
    this.routeParamService.setTemporaryParam(WIZARD_URL_PARAM, true);
    this.form = form;
    this.initialValue = form.value;
    this.patchState({ designerEditMode });
    this.itemsSameFn = itemsSameFn;

    this.form.valueChanges.pipe(startWith(null), takeUntil(this.destroy$)).subscribe(() => {
      this.markFormPristineIfValueWasReverted();
      this.evaluateWizardSteps();
    });
  }

  public closeDialog(result?: boolean): void {
    this.dialogRef.close(result);
  }

  /**
   * Used to change the step in the designer
   * @param index The index of the step to set as active
   */
  public changeStep(index: number): void {
    const stepStatus = this.get().stepStatus;
    if (index <= stepStatus.length - 1 && stepStatus.slice(0, index).every(status => status.completed)) {
      this.patchState({ activeStepIndex: index });
    }
  }

  private markFormPristineIfValueWasReverted(): void {
    if (isEqualSimpleObject(this.initialValue, this.form.value, { customEqualityCheck: this.itemsSameFn })) {
      this.form.markAsPristine();
    }
  }

  private evaluateWizardSteps(): void {
    const stepStatus = Object.values(this.form.controls).map(formGroup => {
      return {
        completed: formGroup.valid,
        optional: !DesignerService.isRequired(formGroup)
      };
    });
    this.patchState({ stepStatus });
  }

  private createViewModel(state: DesignerStoreState): DesignerServiceViewModel {
    const activeStep = state.stepStatus[state.activeStepIndex];
    return {
      designerEditMode: state.designerEditMode,
      stepStatus: state.stepStatus,
      nextButton: {
        visible: state.activeStepIndex < state.stepStatus.length - 1,
        enabled: activeStep?.completed || activeStep?.optional
      },
      backButton: { visible: state.activeStepIndex > 0 },
      completeButton: { enabled: this.form.valid && !isEqualSimpleObject(this.initialValue, this.form.value, { customEqualityCheck: this.itemsSameFn }) }
    };
  }

  /** Used to check if a form group is required
   * @param formGroup The form group to check (can be any AbstractControl descendant)
   * @returns True if the form group has either CelumValidators.required or Validators.required
   */
  protected static isRequired(formGroup: AbstractControl): boolean {
    return formGroup.hasValidator(Validators.required) || formGroup.hasValidator(CelumValidators.required);
  }
}
