import { createActionGroup, props } from '@ngrx/store';

import { Entity, EntityId } from '../entity/entity';
import { EntityRegistrationDeviation, EntityRegistrationDeviations } from '../entity/entity-registry';

// tslint:disable-next-line:variable-name
export const InternalEntityActions =
  createActionGroup({
                      source: `Entity`,
                      events: {
                        // internal upsert action that will be used to populate the store.
                        // the public ones (upsert, upsertMany) are handled by effects which notify the EntityResolver and dispatch this action afterwards
                        // this is necessary as there is no other way to get an action and the state before it was passed through reducers
                        '_Upsert Many': props<{ entities: Entity[], deviations?: EntityRegistrationDeviations }>(),

                        // public actions
                        'Upsert': props<{ entity: Entity, deviation?: EntityRegistrationDeviation<Entity> }>(),
                        'Upsert Many': props<{ entities: Entity[], deviations?: EntityRegistrationDeviations }>(),
                        'Remove': props<{ id: EntityId }>(),
                        'Remove Many': props<{ ids: EntityId[] }>()
                      }
                    });


// we don't make _upsertMany accessible as calling it directly skips calling the EntityResolver
const { _UpsertMany, ...publicActions } = InternalEntityActions;
// tslint:disable-next-line:variable-name
export const EntityActions = publicActions;
