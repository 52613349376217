import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TenantService {
  public static TENANT_LOCAL_STORAGE_KEY = 'tenant';
  public currentTenantId$ = new BehaviorSubject<string>(undefined);

  public switchTenant(tenantId: string, refreshPage: boolean = false): void {
    this.currentTenantId$.next(tenantId);
    this.storeTenant(tenantId);

    if (refreshPage) {
      window.location.assign(`/`);
    }
  }

  public getTenantIdFromUrl(): string {
    const tenantRegex = '.*tenant/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})';
    return window.location.href.match(tenantRegex)?.[1];
  }

  public getCurrentTenantId(): string {
    if (!this.currentTenantId$.getValue()) {
      const storedTenantId = localStorage.getItem(TenantService.TENANT_LOCAL_STORAGE_KEY);
      if (storedTenantId) {
        this.currentTenantId$.next(storedTenantId);
      }
    }

    return this.currentTenantId$.getValue();
  }

  public storeTenant(tenantId: string): void {
    if (tenantId) {
      this.currentTenantId$.next(tenantId);
      localStorage.setItem(TenantService.TENANT_LOCAL_STORAGE_KEY, tenantId);
    }
  }

  public removeTenant(): void {
    this.currentTenantId$.next(undefined);
    localStorage.removeItem(TenantService.TENANT_LOCAL_STORAGE_KEY);
  }
}
