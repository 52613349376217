import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumPipesModule } from '@celum/ng2base';

import { CelumButtonModule } from '../button/button.module';
import { CelumIconModule } from '../icon/icon.module';
import { CelumProgressCircleModule } from '../progress-circle/progress-circle.module';
import { ProgressItem } from './components/progress-item/progress-item';
import { ProgressSnackbar } from './components/progress-snackbar/progress-snackbar';
import { SimpleSnackbar } from './components/simple-snackbar/simple-snackbar';
import { Snackbar } from './components/snackbar/snackbar';

/**
 * ⚠ Requires dependency `@ngrx/store`.
 */
@NgModule({
            imports: [
              CommonModule, TranslateModule, ScrollingModule, MatProgressSpinnerModule, MatButtonModule, MatProgressBarModule, MatTooltipModule,
              CelumPipesModule,
              CelumIconModule, CelumProgressCircleModule, CelumButtonModule
            ],
            declarations: [SimpleSnackbar, Snackbar, ProgressSnackbar, ProgressItem],
            exports: [SimpleSnackbar, Snackbar, ProgressSnackbar, ProgressItem, TranslateModule]
          })
export class CelumSnackbarModule {
}
