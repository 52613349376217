import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { BrowserCheck } from '@celum/core';

import { IconConfiguration } from '../../../icon/icon-configuration';

// tslint:disable-next-line:use-component-selector note: seems like tslint does not understand constants as selectors ;)
@Component({
             selector: Dialog.SELECTOR,
             templateUrl: './dialog.html',
             styleUrls: ['./dialog.less'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class Dialog implements OnInit, OnDestroy {

  public static BLUR_CLASSNAME = 'blurred';
  public static SELECTOR = 'celum-dialog';

  // if true, prevents from having button able to close all opened dialogs
  @Input() public hideCloseAllBtn = false;

  // Based on closeAllBtn click, emits event which should close all opened dialogs
  @Output() public readonly onCloseAll = new EventEmitter<any>();

  private closeAllIcon: IconConfiguration = IconConfiguration.medium('cancel-m', '')
                                                             .withColor('white')
                                                             .withIconSize(50);

  constructor(private element: ElementRef) {
  }

  public get closeAllIconConfig(): IconConfiguration {
    return this.closeAllIcon;
  }

  public ngOnInit(): void {
    setTimeout(() => this.enableDisableDocumentBlur(true));
  }

  public ngOnDestroy(): void {
    setTimeout(() => this.enableDisableDocumentBlur(false));
  }

  public closeAll(event: any): void {
    this.onCloseAll.emit(event);
  }

  private enableDisableDocumentBlur(enable: boolean): void {
    // we add a backdrop filter with CSS which does all of the following stuff automatically in all browsers besides Firefox.
    // there it is hidden behind an experimental flag, so we still have to do this ugly workaround for it
    // remove this whole method if the backdrop filter is enabled by default: https://caniuse.com/#search=backdrop-filter
    if (!BrowserCheck.isFirefox()) {
      return;
    }

    const dialog = this.element.nativeElement as Element;
    const openedDialogs = document.querySelectorAll(Dialog.SELECTOR);
    const openedDialogsLength = Array.from(openedDialogs).find(d => d === dialog) ? openedDialogs.length : openedDialogs.length + 1;

    if (openedDialogsLength > 1) {
      return;
    }

    const dialogContainer = getDialogTopContainer(dialog);

    const ignoreElementWithIds = ['userpilotContent'];

    const children: Element[] = Array.from(document.body.children);
    children.forEach(child => {
      const childIsNotThisDialog = (!dialogContainer || child.classList !== dialogContainer.classList);
      const isInIgnoreList = ignoreElementWithIds.includes(child.id);

      if (child.localName !== 'script' && childIsNotThisDialog && !isInIgnoreList) {
        enable ? child.classList.add(Dialog.BLUR_CLASSNAME) : child.classList.remove(Dialog.BLUR_CLASSNAME);
      }
    });

    function getDialogTopContainer(element: Element): Element {
      if (!element) {
        return null;
      }
      if (element.parentNode === document.querySelector('body')) {
        return element;
      } else {
        return getDialogTopContainer(element.parentElement);
      }
    }
  }
}
