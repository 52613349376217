<div class="dialog-footer_inner-container" [style.background]="color || 'var(--colorPrimary)'">
  <celum-icon class="dialog-footer_close-icon"
              [configuration]="cancelIconConfigNoText"
              (click)="cancel($event)"></celum-icon>
  <div class="dialog-footer_button-wrapper" #ref>
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="ref.children.length == 0">
    <div *ngIf="!buttonPositionCentered" class="dialog-footer_button-wrapper">
      <button *ngIf="(buttons.indexOf('cancel') >= 0 || buttons.indexOf('close') >= 0)" class="dialog-footer_btn darker"
              celum-icon-button
              data-test-dialog-footer-cancel-button
              (click)="cancel($event)">
        <celum-icon [configuration]="cancelIconConfig"></celum-icon>
      </button>
      <button *ngIf="buttons.indexOf('ok') >= 0" class="dialog-footer_btn"
              celum-icon-button
              data-test-dialog-footer-confirm-button
              [disabled]="!valid || loading"
              (click)="confirm((confirmOptionsList && confirmOptionsList.length !== 0) ? currentSelectedOption: $event)">
        <celum-icon [configuration]="internalConfirmIcon" [class.loading]="loading"></celum-icon>
      </button>
      <button *ngIf="confirmOptionsList && confirmOptionsList.length > 1" class="dialog-footer_btn darker dialog-footer_selection"
              mat-button
              data-test-dialog-footer-options-select
              [matMenuTriggerFor]="confirmOptionsMenu">
        <celum-icon [configuration]="selectionIconConfig"></celum-icon>
      </button>
    </div>
    <div *ngIf="buttonPositionCentered" class="dialog-footer_button-wrapper-centered">
      <button class="dialog-footer_btn"
              mat-flat-button celum-button
              data-test-dialog-footer-confirm-button
              color="primary"
              size="small"
              [disabled]="!valid || loading"
              (click)="confirm((confirmOptionsList && confirmOptionsList.length !== 0) ? currentSelectedOption: $event)">
        <celum-icon [configuration]="internalConfirmIcon" [class.loading]="loading"></celum-icon>
      </button>
    </div>
  </ng-container>
</div>

<mat-menu #confirmOptionsMenu="matMenu" yPosition="above">
  <ng-container *ngFor="let option of currentDisplayedOptions ">
    <button (click)="changeOption(option)" mat-menu-item>{{option.text | translate}}</button>
  </ng-container>
</mat-menu>
