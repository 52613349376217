import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { callExternalMethod, DataContext, Operation, ReactiveService } from '@celum/core';

@Injectable()
export class DisabledOperationService extends ReactiveService implements OnDestroy {

  private subscription: Subscription;
  private toolTipMsg$ = new BehaviorSubject<string>(null);

  public get disabled$(): Observable<boolean> {
    return this.toolTipMsg$.pipe(map(() => this.isDisabled));
  }

  public get isDisabled(): boolean {
    // tslint:disable-next-line:triple-equals
    return this.toolTipMsg$.getValue() != null;
  }

  public get disabledMsg$(): Observable<string> {
    return this.toolTipMsg$.asObservable();
  }

  public ngOnDestroy(): void {
    this.destroy();
    this.toolTipMsg$.complete();
  }

  public update(operation: Operation, context: DataContext): void {
    if (context && operation) {
      this.updateDisableText(operation, context);
    }
  }

  private updateDisableText(operation: Operation, context: DataContext): void {
    this.subscription?.unsubscribe();

    if (!operation.disabledHoverText) {
      this.toolTipMsg$.next(null);
      return;
    }

    const disabledText$ = callExternalMethod(
      () => operation.disabledHoverText(context),
      error => {
        console.warn(`OperationsManager: "disabledHoverText" method of operation ${operation.getKey()} threw an error!`, error);
        return null;
      }
    );

    this.subscription = disabledText$.pipe(takeUntil(this.unsubscribe$)).subscribe(text => this.handleText(text));
  }

  private handleText(disabledText: string): void {
    // only null means not disabled, as soon as there is something - even an empty tooltip, the operation should be disabled (make sure to already emit null
    // and not potentially "undefined")
    this.toolTipMsg$.next(disabledText ?? null);
  }
}
