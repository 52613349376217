import { DataUtil, isArrayEqual } from '@celum/core';

import {
  AddChildrenForNodes, ChangeRootNodeSelection, CollapseNode, ExpandNode, NrOfRootNodes, ReplaceChildrenForNode, RootNodesLoaded, SelectNode, TreeActions,
  TreeActionTypes
} from './tree-actions';
import { TreeState } from './tree-state';

const initialState: TreeState = {
  nodesByParent: new Map<string, string[]>(),
  trees: {}
};

export function treeReducer(state: TreeState = initialState, action: TreeActions): TreeState {

  switch (action.type) {
    case TreeActionTypes.Expand: {
      return expandNode(state, action);
    }
    case TreeActionTypes.RegisterTree: {
      const trees = { ...state.trees };

      if (!trees[action.treeId]) {
        trees[action.treeId] = {
          nrOfRootNodes: -1,
          selectedNode: undefined,
          expandedNodes: [],
          rootNodes: undefined,
          rootNodeSelected: false
        };

        return {
          ...state,
          trees
        };
      } else {
        return state;
      }
    }
    case TreeActionTypes.ChangeRootNodeSelection: {
      return changeRootNodeSelection(state, action);
    }
    case TreeActionTypes.Collapse: {
      return collapseNode(state, action);
    }
    case TreeActionTypes.SelectNode: {
      return selectNode(state, action);
    }
    case TreeActionTypes.NrOfRootNodes: {
      return updateNrOfRootNodes(state, action);
    }
    case TreeActionTypes.RootNodesLoaded: {
      return rootNodesLoaded(state, action);
    }
    case TreeActionTypes.AddChildrenForNodes: {
      return addChildrenForNode(state, action);
    }
    case TreeActionTypes.ReplaceChildrenForNode: {
      return addChildrenForNode(state, action, true);
    }
    case TreeActionTypes.ResetTrees: {
      return { ...initialState };
    }
    case TreeActionTypes.ResetTree: {
      return {
        ...state,
        trees: {
          ...state.trees,
          [action.treeId]: {
            nrOfRootNodes: -1,
            selectedNode: undefined,
            expandedNodes: [],
            rootNodes: undefined,
            rootNodeSelected: false
          }
        }
      };
    }
  }

  return state;
}

export function addChildrenForNode(state: TreeState, action: AddChildrenForNodes | ReplaceChildrenForNode, replace: boolean = false): TreeState {
  let changed = false;

  const childrenForParent = new Map(state.nodesByParent);

  const childrenIds = (childrenForParent.has(action.parentId) && !replace) ? [...childrenForParent.get(action.parentId)] : [];

  action.childrenIds.forEach(childId => {
    if (!childrenIds.includes(childId)) {
      childrenIds.push(childId);
      changed = true;
    }
  });

  // make sure to allow setting children to 0
  if (!changed && replace && DataUtil.isEmpty(action.childrenIds)) {
    changed = true;
  }

  if (changed) {
    childrenForParent.set(action.parentId, childrenIds);

    return {
      ...state,
      nodesByParent: childrenForParent
    };
  } else {
    return state;
  }
}

export function rootNodesLoaded(state: TreeState, action: RootNodesLoaded): TreeState {
  const treeState = state.trees[action.treeId];

  // no such tree or nothing changed
  if (!treeState) {
    return state;
  }

  const newTree = { ...treeState };
  const newTrees = { ...state.trees };

  let changed = false;

  if (!newTree.rootNodes) {
    changed = true;
  } else {
    // check if something has changed
    changed = !isArrayEqual(action.rootNodeIds, newTree.rootNodes);
  }

  if (changed) {
    newTree.rootNodes = action.rootNodeIds;
    newTrees[action.treeId] = newTree;

    return {
      ...state,
      trees: newTrees
    };
  } else {
    return state;
  }
}

export function updateNrOfRootNodes(state: TreeState, action: NrOfRootNodes): TreeState {
  const treeState = state.trees[action.treeId];

  // no such tree or nothing changed
  if (!treeState || treeState.nrOfRootNodes === action.nrOfRootNodes) {
    return state;
  }

  const newTree = { ...treeState };
  const newTrees = { ...state.trees };

  newTree.nrOfRootNodes = action.nrOfRootNodes;
  newTrees[action.treeId] = newTree;

  return {
    ...state,
    trees: newTrees
  };
}

export function selectNode(state: TreeState, action: SelectNode): TreeState {
  const treeState = state.trees[action.treeId];

  // no such tree or nothing changed
  if (!treeState || treeState.selectedNode === action.nodeId) {
    return state;
  }

  const newTree = { ...treeState };
  const newTrees = { ...state.trees };

  newTree.selectedNode = action.nodeId;
  newTree.rootNodeSelected = false;
  newTrees[action.treeId] = newTree;

  return {
    ...state,
    trees: newTrees
  };
}

export function collapseNode(state: TreeState, action: CollapseNode): TreeState {
  const treeState = state.trees[action.treeId];

  if (!treeState) {
    return state;
  }

  const newTree = { ...treeState };
  const newTrees = { ...state.trees };
  const nodes = [...newTree.expandedNodes];

  let nodeRemoved = false;

  if (Array.isArray(action.nodeId)) {
    action.nodeId.forEach(nodeId => {
      const index = nodes.indexOf(nodeId);
      if (index > -1) {
        nodes.splice(index, 1);
        nodeRemoved = true;
      }
    });
  } else if (nodes.indexOf(action.nodeId) > -1) {
    nodes.splice(nodes.indexOf(action.nodeId), 1);
    nodeRemoved = true;
  }

  if (nodeRemoved) {
    newTree.expandedNodes = nodes;
    newTrees[action.treeId] = newTree;

    return {
      ...state,
      trees: newTrees
    };
  } else {
    return state;
  }
}

export function expandNode(state: TreeState, action: ExpandNode): TreeState {
  const treeState = state.trees[action.treeId];

  if (!treeState) {
    return state;
  }

  const newTree = { ...treeState };
  const newTrees = { ...state.trees };

  let nodeAdded = false;

  if (Array.isArray(action.nodeId)) {
    const nodes = [...newTree.expandedNodes];

    action.nodeId.forEach(nodeId => {
      if (nodes.indexOf(nodeId) === -1) {
        nodes.push(nodeId);
        nodeAdded = true;
      }
    });

    if (nodeAdded) {
      newTree.expandedNodes = nodes;
    }
  } else if (treeState.expandedNodes.indexOf(action.nodeId) === -1) {
    newTree.expandedNodes = [...newTree.expandedNodes, action.nodeId];
    nodeAdded = true;
  }

  if (nodeAdded) {
    newTrees[action.treeId] = newTree;

    return {
      ...state,
      trees: newTrees
    };
  } else {
    return state;
  }
}

export function changeRootNodeSelection(state: TreeState, action: ChangeRootNodeSelection): TreeState {
  const treeState = state.trees[action.treeId];

  // no such tree or nothing changed
  if (!treeState || treeState.rootNodeSelected === action.selected) {
    return state;
  }

  // erase other selection if root node is selected
  const newTreeState = action.selected ? {
    ...treeState,
    rootNodeSelected: true,
    selectedNode: undefined
  } : {
    ...treeState,
    rootNodeSelected: false
  };
  const newTrees = { ...state.trees };
  newTrees[action.treeId] = newTreeState;
  return {
    ...state,
    trees: newTrees
  };
}
