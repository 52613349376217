import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconConfiguration, ICON_COLOR_VARIANT } from '../../icon/icon-configuration';
import { AvatarConfigBuilder, AvatarConfiguration, AvatarLabel, AVATAR_SIZE, LABEL_POSITION, TEXT_SIZE } from '../avatar-configuration';

/**
 * Displays a circular avatar with a given image, or the initials of the person. There are multiple configuration options available, eg to define a background
 * color, size of the avatar...
 */
@Component({
             selector: 'celum-avatar',
             templateUrl: './avatar.html',
             styleUrls: ['./avatar.less'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CelumAvatar implements OnChanges, OnInit {

  public static readonly TEXT_LENGTH = 2;
  public static readonly MAX_SIZE = 200;

  /**
   * Pass a configuration for the avatar to show. If not defined a "placeholder" configuration will be created based on the `avatarPlaceholderSize`
   * or `AVATAR_SIZE.s` if this is not defined.
   */
  @Input() public configuration: AvatarConfiguration;
  /**
   * Define the size of the placeholder avatar that is created if no `configuration` is passed.
   */
  @Input() public avatarPlaceholderSize: number = AVATAR_SIZE.s;

  /**
   * Emits when the overlay was clicked (only if one is configured of course).
   */
  @Output() public readonly overlayClicked = new EventEmitter<Event>();

  @HostBinding('class.celum-avatar--inactive') public inactivePersonCls = false;

  @HostBinding('class') public hostCls = '';

  public bottomLabels$: Observable<IconConfiguration[]>;
  public topLabels$: Observable<IconConfiguration[]>;

  constructor(private element: ElementRef) {
  }

  @HostBinding('class.celum-avatar--bordered')
  public get borderedCls(): boolean {
    return !!this.configuration?.borderColor;
  }

  @HostBinding('class.celum-avatar--bottom-labels')
  public get bottomLabelsCls(): Observable<boolean> {
    return this.bottomLabels$?.pipe(map(labels => labels && labels.length > 0));
  }

  @HostBinding('class.celum-avatar--top-labels')
  public get topLabelsCls(): Observable<boolean> {
    return this.topLabels$?.pipe(map(labels => labels && labels.length > 0));
  }

  @HostBinding('attr.person-id') get personId(): string {
    return this.configuration ? this.configuration.email : '';
  }

  public get userEmail(): string {
    return this.configuration ? this.configuration.email : '';
  }

  public get avatarSizeClassName(): string {
    return this.defineAvatarSizeModifier();
  }

  public get avatarSize(): number {
    return !this.configuration ? this.avatarPlaceholderSize / 10 :
           (this.configuration.size > CelumAvatar.MAX_SIZE ? CelumAvatar.MAX_SIZE : this.configuration.size) / 10;
  }

  public get avatarPlaceholderFontSize(): number {
    return !this.configuration ? this.avatarPlaceholderSize :
           CelumAvatar.defineAvatarPlaceholderFontSize(this.configuration.size || this.avatarPlaceholderSize, this.avatarPlaceholderSize);
  }

  public get userName(): string {
    return !this.configuration ? '' : this.configuration.displayText;
  }

  // Generate 2 letters (first from "name", second from "surname") for avatar placeholder.
  public get avatarTextPlaceholder(): string {
    let username = '';

    if (!this.configuration) {
      return '';
    }

    // Take title if displayText is undefined, to be backwards compatible
    username = this.configuration.displayText ? this.configuration.displayText : this.configuration.title;

    return username.indexOf(' ') > 0 ? username.trim().split(' ').map((partial: string, index: number) => {
      // tslint:disable-next-line:no-bitwise
      return index < (this.configuration.titleLength | CelumAvatar.TEXT_LENGTH) ? partial[0] : '';
      // tslint:disable-next-line:no-bitwise
    }).join('') : username.substr(0, (this.configuration.titleLength | CelumAvatar.TEXT_LENGTH));
  }

  public get imageAvailable(): boolean {
    return !!this.configuration && !!this.configuration.image;
  }

  public ngOnInit(): void {
    if (!this.configuration) {
      this.createAndAddPlaceholder();
    }
  }

  public ngOnChanges({ configuration, avatarPlaceholderSize }: SimpleChanges): void {
    // tslint:disable-next-line:triple-equals
    if (configuration?.currentValue && configuration.previousValue != configuration.currentValue) {
      this.applyConfiguration(configuration.currentValue, configuration.previousValue);
    }

    if (avatarPlaceholderSize?.currentValue && !this.configuration) {
      this.createAndAddPlaceholder();
    }
  }

  public onOverlayClicked(event: Event): void {
    this.overlayClicked.emit(event);
  }

  public getElement(): HTMLElement {
    return this.element.nativeElement;
  }

  protected defineAvatarSizeModifier(): string {
    switch (this.configuration.size) {
      case AVATAR_SIZE.s:
        return 'small';
      case AVATAR_SIZE.m:
        return 'medium';
      case AVATAR_SIZE.l:
        return 'large';
      default:
        return 'custom';
    }
  }

  private createAndAddPlaceholder(): void {
    this.configuration = this.makeAvatarPlaceholder();
    this.applyConfiguration(this.configuration, null);
  }

  private applyConfiguration(config: AvatarConfiguration, previousConfig: AvatarConfiguration): void {

    const iconConfigForAvatarSize = {
      [+AVATAR_SIZE.s]: { iconSize: 8, borderWidth: 0.1 },
      [+AVATAR_SIZE.m]: { iconSize: 8, borderWidth: 0.1 },
      [+AVATAR_SIZE.xl]: { iconSize: 15, borderWidth: 0.2 },
      [+AVATAR_SIZE.xxxl]: { iconSize: 20, borderWidth: 0.2 }
    };

    this.bottomLabels$ = of(config.labels
                                  .filter((label: AvatarLabel) => LABEL_POSITION.BOTTOM_RIGHT === label.position)
                                  .map((label: AvatarLabel) => {
                                    if (iconConfigForAvatarSize[config.size]) {
                                      return IconConfiguration.small(label.icon, label.title)
                                                              .withIconSize(iconConfigForAvatarSize[config.size].iconSize)
                                                              .withColor('transparent')
                                                              .withBackground(label.backgroundColor, true, iconConfigForAvatarSize[config.size].borderWidth);
                                    } else {
                                      return null;
                                    }
                                  })
                              // tslint:disable-next-line:triple-equals
                                  .filter(value => value != null));

    // as soon as needed - add s/l definitions. for custom sizes we can't do the positioning properly anyways...
    this.topLabels$ = of(config.labels
                               .filter((label: AvatarLabel) => LABEL_POSITION.TOP_LEFT === label.position)
                               .map((label: AvatarLabel) => {
                                 if (iconConfigForAvatarSize[config.size]) {
                                   return IconConfiguration.small(label.icon, label.title)
                                                           .withIconSize(iconConfigForAvatarSize[config.size].iconSize)
                                                           .withColorVariant(ICON_COLOR_VARIANT.light)
                                                           .withBackground(label.backgroundColor, true, iconConfigForAvatarSize[config.size].borderWidth);
                                 } else {
                                   return null;
                                 }
                               })
                           // tslint:disable-next-line:triple-equals
                               .filter(value => value != null));

    this.addCssClasses(config, previousConfig);
  }

  private addCssClasses(config: AvatarConfiguration, previousConfig: AvatarConfiguration): void {
    if (!this.hostCls.includes('celum-avatar ')) {
      this.hostCls = 'celum-avatar ' + this.hostCls;
    }

    // remove old cssClass
    if (previousConfig?.cssClass?.length > 0) {
      this.hostCls = this.hostCls.replace(previousConfig.cssClass, '');
    }

    // remove old size class
    if (previousConfig?.size) {
      this.hostCls = this.hostCls.replace('celum-avatar--size-' + previousConfig.size, '');
    }

    // add new classes
    this.hostCls += `${config.cssClass} celum-avatar--size-${config.size}`;
  }

  private makeAvatarPlaceholder(): AvatarConfiguration {
    return new AvatarConfigBuilder().placeHolder(this.avatarPlaceholderSize,
                                                 CelumAvatar.defineAvatarPlaceholderFontSize(this.avatarPlaceholderSize, this.avatarPlaceholderSize)).build();
  }

  private static defineAvatarPlaceholderFontSize(avatarSize: number, placeholderSize: number): number {
    switch (avatarSize) {
      case AVATAR_SIZE.s:
        return TEXT_SIZE.s;
      case AVATAR_SIZE.m:
        return TEXT_SIZE.m;
      case AVATAR_SIZE.l:
        return TEXT_SIZE.l;
      case AVATAR_SIZE.xl:
        return TEXT_SIZE.xl;
      case AVATAR_SIZE.xxxl:
        return TEXT_SIZE.xxxl;
      default:
        return placeholderSize || TEXT_SIZE.m;
    }
  }

}
