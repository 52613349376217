import { AvatarConfigBuilder, AvatarConfiguration, AVATAR_SIZE, ColorConstants, IconConfiguration } from '@celum/common-components';
import { AccountUser, InvitationStatus, UserState } from '@celum/shared/domain';

const PENDING_BACKGROUND_COLOR = 'rgba(244, 99, 25, 0.08)';
const PENDING_BORDER_COLOR = '#f46319';
const LABEL_PREFIX = 'SHARED.USER_AVATAR';

export interface AccountUserToAvatarOptions {
  accountAccessToken: string;
  tooltipText: string;

  size?: AVATAR_SIZE;
  showTooltip?: boolean;
  showDisplayName?: boolean;
}

export function accountUserToAvatar(user: Partial<AccountUser>, options: AccountUserToAvatarOptions): AvatarConfiguration {
  // tslint:disable-next-line:variable-name
  const _options = { size: AVATAR_SIZE.m, showTooltip: true, showDisplayName: false, ...options };

  let userAvatarConfigBuilder: AvatarConfigBuilder<AvatarConfiguration>;

  if (!user || user.status === UserState.DELETED) {
    userAvatarConfigBuilder = deletedAvatarConfig(_options.size, _options.showTooltip);
  } else if (user.status === UserState.INACTIVE) {
    userAvatarConfigBuilder = getInactiveConfig(user, _options);
  } else if (user.invitationStatus === InvitationStatus.INVITED) {
    userAvatarConfigBuilder = getInvitedConfig(user, _options);
  } else if (user.invitationStatus === InvitationStatus.PENDING_APPROVAL) {
    userAvatarConfigBuilder = getPendingConfig(user, _options);
  } else {
    userAvatarConfigBuilder = getActiveConfig(user, _options);
  }

  return userAvatarConfigBuilder.withSize(_options.size).build();
}

export function deletedAvatarConfig(size: AVATAR_SIZE, showTooltip: boolean): AvatarConfigBuilder<AvatarConfiguration> {
  return new AvatarConfigBuilder()
    .withBackgroundColor(ColorConstants.SYSTEM_WHITE)
    .withDisplayName(`${LABEL_PREFIX}.DELETED`, false)
    .withIcon(new IconConfiguration('user-removed').withIconSize(size).withColor(ColorConstants.BLUE_GRAY_400))
    .withTooltip(showTooltip ? `${LABEL_PREFIX}.DELETED` : null);
}

function getPendingConfig(user: Partial<AccountUser>, options: AccountUserToAvatarOptions): AvatarConfigBuilder<AvatarConfiguration> {
  return new AvatarConfigBuilder()
    .withBackgroundColor(PENDING_BACKGROUND_COLOR)
    .withBorderColor(PENDING_BORDER_COLOR)
    .withDisplayName(`${user.firstName} ${user.lastName}`, options.showDisplayName)
    .withImage(profilePictureSource(user, options.accountAccessToken))
    .withTextColor(PENDING_BORDER_COLOR)
    .withTooltip(options.showTooltip ? `${LABEL_PREFIX}.PENDING` : null, { name: `${user.firstName} ${user.lastName}` });
}

function getInvitedConfig(user: Partial<AccountUser>, options: AccountUserToAvatarOptions): AvatarConfigBuilder<AvatarConfiguration> {
  return new AvatarConfigBuilder()
    .withBackgroundColor(ColorConstants.BLUE_GRAY_900)
    .withBorderColor(ColorConstants.BLUE_GRAY_900)
    .withIcon(new IconConfiguration('invite').withIconSize(options.size - 2).withColor(ColorConstants.BLUE_GRAY_050))
    .withImage(profilePictureSource(user, options.accountAccessToken))
    .withOpacity(0.8)
    .withTooltip(options.showTooltip ? `${LABEL_PREFIX}.INVITED` : null, { email: user.email });
}

function getInactiveConfig(user: Partial<AccountUser>, options: AccountUserToAvatarOptions): AvatarConfigBuilder<AvatarConfiguration> {
  return new AvatarConfigBuilder()
    .withBackgroundColor(ColorConstants.BLUE_GRAY_400)
    .withBorderColor(ColorConstants.BLUE_GRAY_900)
    .withDisplayName(`${user.firstName} ${user.lastName}`, options.showDisplayName)
    .withImage(profilePictureSource(user, options.accountAccessToken))
    .withTooltip(options.showTooltip ? `${LABEL_PREFIX}.DEACTIVATED` : null, { name: `${user.firstName} ${user.lastName}` });
}

function getActiveConfig(user: Partial<AccountUser>, options: AccountUserToAvatarOptions): AvatarConfigBuilder<AvatarConfiguration> {
  return new AvatarConfigBuilder()
    .withBackgroundColor(ColorConstants.BLUE_GRAY_400)
    .withDisplayName(`${user.firstName} ${user.lastName}`, options.showDisplayName)
    .withImage(profilePictureSource(user, options.accountAccessToken))
    .withTooltip(options.showTooltip ? options.tooltipText || `${user.firstName} ${user.lastName}` : null);
}

function profilePictureSource(user: Partial<AccountUser>, accountAccessToken: string): string {
  return user?.profilePictureDownloadLink ? `${user?.profilePictureDownloadLink}&type=preview_small&token=${accountAccessToken}` : '';
}
