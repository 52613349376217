import { ChangeDetectorRef, Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { BrowserCheck } from '@celum/core';

import { ReactiveComponent } from '../util/reactive-component';
import { ResizeObserverHelper } from './resize-observer-helper';

/**
 * Directive to be applied to an element with a tooltip that should be only shown if the text contained in the element is truncated.
 *
 * ⚠ Requires a [matTooltip] on the element this directive is applied to!
 * ⚠ Requires the element to be configured via CSS to truncate its content if it does not fit!
 *
 * Usage:
 * ```
 * <span class="list-card-item_name"
 *       spaceAwareTooltip
 *       [matTooltip]="entity?.name">{{ entity?.name }}</span>
 * ```
 *
 * The mat tooltip will be only shown if the text in `entity.name` contained in the `span` does not fit the available space. As long as the space is big enough
 * for the test, the tooltip will not be shown!
 */
@Directive({ selector: '[spaceAwareTooltip]' })
export class SpaceAwareTooltipDirective extends ReactiveComponent implements OnChanges, OnInit, OnDestroy {

  // tslint:disable-next-line:no-input-rename
  @Input('matTooltip') public tooltipText: string;
  /** Specify the debounce time of the resize events as there will be a lot events during manual resizing or animations - changing it is not supported! */
  @Input() public spaceAwareDebounceTime = 100;

  private currentTruncated = false;

  private resizeObserverHelper: ResizeObserverHelper;

  constructor(private element: ElementRef, private ngZone: NgZone, private changeDetector: ChangeDetectorRef, private matTooltip: MatTooltip) {
    super();
  }

  public ngOnInit(): void {
    this.clearMessage();

    if (this.directiveDisabled()) {
      return;
    }

    this.resizeObserverHelper = new ResizeObserverHelper(this.element.nativeElement, this.spaceAwareDebounceTime, this.unsubscribe$);

    this.resizeObserverHelper.startListen().subscribe(entry => {
      this.ngZone.run(() => {
        this.checkTruncated(entry.target, false);
      });
    });
  }

  public ngOnChanges({ tooltipText }: SimpleChanges): void {
    if (this.directiveDisabled()) {
      return;
    }

    if (tooltipText && !tooltipText.isFirstChange()) {
      // delay the check to make sure that the text is already applied to the dom element and its values changed accordingly
      requestAnimationFrame(() => {
        this.checkTruncated(this.element.nativeElement, true);
      });
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resizeObserverHelper?.cleanup();
  }

  // early exit on Safari due to https://github.com/angular/components/issues/7469
  private directiveDisabled(): boolean {
    return BrowserCheck.isSafari();
  }

  private checkTruncated(element: Element, textChanged: boolean): void {
    const isTruncated = element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;

    if (isTruncated !== this.currentTruncated) {
      this.currentTruncated = isTruncated;
      this.matTooltip.message = this.currentTruncated ? this.tooltipText : '';

      if (!this.currentTruncated) {
        this.matTooltip.hide();
      }

      this.changeDetector.markForCheck();
    } else if (textChanged && !this.currentTruncated) {
      // if the message has changed but not the truncation state, make sure to clear the message again if not truncated!
      this.clearMessage();
    }
  }

  private clearMessage(): void {
    this.matTooltip.message = '';
    this.matTooltip.hide();
  }
}
