import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { enableMapSet } from 'immer';

import {
  AuthInterceptor,
  AuthInterceptorConfig,
  CelumAuthModule,
  ServiceTokenInterceptorConfig,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  tenantHrefFactory,
  TenantService
} from '@celum/authentication';
import { CelumSnackbarModule } from '@celum/common-components';
import { CelumPropertiesProvider } from '@celum/core';
import { CelumSnackbarListModule, SnackbarList } from '@celum/internal-components';
import { LibrariesProperties } from '@celum/libraries/domain';
import { DashboardComponent } from '@celum/libraries/features/dashboard';
import { translateLibraryPaginationResult } from '@celum/libraries/shared';
import { CelumEntityModule, CustomTranslateLoader, providePaginationTranslator, TranslationHelper } from '@celum/ng2base';
import { SystembarComponent } from '@celum/shared/ui';
import { AppErrorHandler } from '@celum/shared/util';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http, ['', 'common', 'shared']);
}

// Important: We join all our translations in one file, so this should be the only place, where we use the Translate Module!
const translate = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: translationFactory,
    deps: [HttpClient]
  }
});

enableMapSet();

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'NgRx Store DevTools',
      maxAge: 50,
      logOnly: environment.production,
      serialize: {
        options: {
          set: true,
          map: true
        }
      },
      connectInZone: true
    }),
    translate,

    CelumAuthModule.forRoot({
      logLevel: LogLevel.Error
    }),
    CelumEntityModule,
    CelumSnackbarListModule,
    CelumSnackbarModule,

    DashboardComponent,
    SystembarComponent
  ],
  providers: [
    TranslationHelper,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
      useFactory: serviceTokenInterceptorConfigFactory,
      deps: [TenantService]
    },
    // TODO: Move this to the providers of the @celum/authentication-module once SPOR-198 gets implemented, so that applications automatically use
    // the tenant-href
    {
      provide: APP_BASE_HREF,
      deps: [TenantService],
      useFactory: tenantHrefFactory
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true
      }
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    providePaginationTranslator(translateLibraryPaginationResult)
  ],
  bootstrap: [AppComponent, SnackbarList]
})
export class AppModule {}

function serviceTokenInterceptorConfigFactory(tenantService: TenantService): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const getConfiguration = () => {
    const tenantId = tenantService.getCurrentTenantId();

    return {
      apiConfigurations: [
        {
          apiUrls: [LibrariesProperties.properties.apiUrl],
          serviceTokenRequestDto: {
            clientId: 'slib',
            orgId: tenantId
          },
          tenantIdHeader: tenantId
        },
        {
          // Need to use the CelumPropertiesProvider to not import anything from experience/domain
          apiUrls: [CelumPropertiesProvider.properties.appProperties.experience.apiUrl],
          serviceTokenRequestDto: {
            clientId: 'spor',
            orgId: tenantId
          },
          tenantIdHeader: tenantId
        }
      ]
    };
  };

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
