import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent as observableFromEvent, merge as observableMerge, Subject, timer as observableTimer } from 'rxjs';
import { filter, map, mergeMapTo, share, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

/**
 * This directive decides, if the click or tap should be emitted
 * It utilizes the fact, that while tapping, tap happens first and click second, but on non-touch device, only click happens
 * If there are elements passed in the constructor, it'll apply the directive to these, otherwise it'll bind to the template element
 */
@Directive({ selector: '[click-tap]', standalone: true })
export class ClickTapDirective implements OnDestroy, OnInit {

  /** event emitted when the element is clicked */
  @Output() public readonly onClick = new EventEmitter<MouseEvent>();
  /** event emitted when the element is tapped */
  @Output() public readonly onTap = new EventEmitter<Event>();
  /** event emitted when the element is double-clicked */
  @Output() public readonly onDoubleClick = new EventEmitter<MouseEvent>();
  /** event emitted when the element is double tapped */
  @Output() public readonly onDoubleTap = new EventEmitter<Event>();

  private duration = 400;
  private click$ = new Subject<MouseEvent>();
  private touchStart$ = new Subject<TouchEvent>();
  private ngUnsubscribe$ = new Subject<void>();

  constructor(private element: ElementRef) {
    this.initialize();
  }

  public ngOnInit(): void {
    const el = this.element.nativeElement as HTMLElement;
    el.addEventListener('touchstart', e => this.touchStart$.next(e), { passive: true });
    el.addEventListener('click', e => this.click$.next(e), { passive: true });
  }

  public initialize(): void {
    const clickTap$ = this.click$.pipe(map(this.toTimeEvent), withLatestFrom(this.touchStart$.pipe(startWith(null), map(this.toTimeEvent))),
                                       map(([click, touch]) => ({
                                         event: click.event,
                                         isTap: !!touch.event && click.timestamp - touch.timestamp <= this.duration
                                       })));
    const click$ = clickTap$.pipe(filter(eventObject => !eventObject.isTap), map(eventObject => eventObject.event), tap(e => this.onClick.emit(e)), share());
    const tap$ = clickTap$.pipe(filter(eventObject => eventObject.isTap), map(eventObject => eventObject.event), tap(e => this.onTap.emit(e)), share());
    const doubleClick$ = click$.pipe(mergeMapTo(click$.pipe(take(1), takeUntil(observableTimer(this.duration)))), tap(ev => this.onDoubleClick.emit(ev)));
    const doubleTap$ = tap$.pipe(mergeMapTo(tap$.pipe(take(1), takeUntil(observableTimer(this.duration)))), tap(ev => this.onDoubleTap.emit(ev)));

    observableMerge(click$, tap$, doubleClick$, doubleTap$).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  public setEventElements(elements: Element[]): void {
    const elementListener = elements.map(el => {
      const click$ = observableFromEvent<MouseEvent>(el, 'click').pipe(tap(event => this.click$.next(event)));
      const touchStart$ = observableFromEvent<TouchEvent>(el, 'touchstart').pipe(tap(event => this.touchStart$.next(event)));
      return observableMerge(click$, touchStart$);
    });

    observableMerge(...elementListener).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  // Custom event with timestamp as event.timeStamp is not available on all browsers/platforms
  // https://developer.mozilla.org/en-US/docs/Web/API/Event/timeStamp
  public toTimeEvent<T>(event: T): { event: T, timestamp: number } {
    return {
      event,
      timestamp: Date.now()
    };
  }
}
