import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumInputsModule } from '@celum/common-components';
import { CelumPipesModule, TranslationModule } from '@celum/ng2base';

import { AvatarComponent } from './components/avatar/avatar-component';
import { HelpComponent } from './components/help-component/help-component';
import { ServiceTabsComponent } from './components/service-tabs/service-tabs-component';
import { ServiceTabItemComponent } from './components/service/service-tab-item-component';
import { SubServiceTabItemComponent } from './components/sub-service/sub-service-tab-item-component';
import { CelumSystembarComponent } from './components/systembar/systembar-component';

@NgModule({
            imports: [
              TranslateModule, CommonModule, MatMenuModule, MatDividerModule, MatSelectModule, MatTooltipModule,

              CelumPipesModule, CelumInputsModule, TranslationModule
            ],
            declarations: [
              CelumSystembarComponent, ServiceTabsComponent, ServiceTabItemComponent, SubServiceTabItemComponent,
              AvatarComponent, HelpComponent
            ],
            exports: [CelumSystembarComponent, ServiceTabItemComponent, ServiceTabsComponent, SubServiceTabItemComponent]
          })
export class CelumSystembarModule {
}
