import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CelumIconModule } from '../icon/icon.module';
import { CelumProgressCircle } from './component/progress-circle';

@NgModule({
            imports: [
              CommonModule, MatProgressSpinnerModule, CelumIconModule
            ],
            declarations: [
              CelumProgressCircle
            ],
            exports: [
              CelumProgressCircle, MatProgressSpinnerModule
            ]
          })
export class CelumProgressCircleModule {
}
