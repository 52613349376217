import {
  ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation
} from '@angular/core';

import { Key } from '@celum/core';
import { ReactiveComponent } from '@celum/ng2base';

import { IconConfiguration, ICON_COLOR_VARIANT } from '../../../icon';

export type buttonType = 'ok' | 'cancel' | 'confirmOptions' | 'close';

export interface OptionValue {
  id: string;
  text: string;
  confirmIconName: string;
}

@Component({
             selector: 'celum-dialog-footer',
             templateUrl: './dialog-footer.html',
             styleUrls: ['./dialog-footer.less'],
             encapsulation: ViewEncapsulation.None,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class DialogFooter extends ReactiveComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public cancelBtnText = 'COMMON.CANCEL';
  @Input() public confirmBtnText = 'COMMON.OK';
  @Input() public buttons: buttonType[] = ['ok', 'cancel'];

  @Input() public color: string;
  @Input() public disableOnEnterConfirmation = false;
  @Input() public multipleConfirmations = false;
  @Input() public valid = true;

  @Input() public buttonPosition: FooterButtonPosition = 'stretch';

  /**
   * Property which defines confirm options
   */
  @Input() public confirmOptionsList: OptionValue[];

  @Input() public loading = false;

  @Input() public confirmIconConfig: IconConfiguration;

  @Output() public readonly onCancel = new EventEmitter<any>();

  @Output() public readonly onConfirm = new EventEmitter<any>();

  @HostBinding('class.dialog-footer') public cls = true;
  public cancelIconConfig: IconConfiguration;
  public cancelIconConfigNoText = IconConfiguration.medium('cancel-m', '');
  public selectionIconConfig = IconConfiguration.small('arrow-up-xs', '')
                                                .withIconSize(10)
                                                .withColor('white')
                                                .withColorVariant(ICON_COLOR_VARIANT.light);
  /**
   * Property which defines current selected option
   */
  public currentSelectedOption: OptionValue;
  /**
   * Property which defines which options should be displayed in footer menu
   */
  public currentDisplayedOptions: OptionValue[];

  public internalConfirmIcon: IconConfiguration;

  // dialog was either confirmed or canceled
  private confirmed = false;

  constructor(private element: ElementRef) {
    super();
  }

  @HostBinding('class.dialog-footer--centered-button')
  public get buttonPositionCentered(): boolean {
    return this.buttonPosition === 'centered';
  }

  public ngOnInit(): void {
    // keypress handling
    if (!this.disableOnEnterConfirmation) {
      window.addEventListener('keypress', this.onKeyPress);
    }

    this.updateIconConfigurations();
  }

  public ngOnChanges({ cancelBtnText, confirmBtnText, confirmOptionsList, buttons, buttonPosition }: SimpleChanges): void {
    if (confirmOptionsList && this.confirmOptionsList && this.confirmOptionsList.length !== 0) {
      this.currentSelectedOption = this.confirmOptionsList[0];
      this.currentDisplayedOptions = this.confirmOptionsList.filter(option => option.id !== this.confirmOptionsList[0].id);
    }

    if (buttons && this.buttons.includes('close') && this.cancelBtnText === 'COMMON.CANCEL') {
      this.cancelBtnText = 'COMMON.CLOSE';
    }

    if (confirmOptionsList || confirmBtnText || cancelBtnText) {
      this.updateIconConfigurations();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.cleanup();
  }

  public cancel(event: any): void {
    (this.element.nativeElement as HTMLElement).style.position = 'static'; // prevent position 'fixed', which makes the dialog's fade out ugly
    this.onCancel.emit(event);
  }

  public confirm(event: any): void {
    if (this.valid) {
      if (!this.confirmed || this.multipleConfirmations) {
        this.onConfirm.emit(event);
        this.confirmed = true;
      }
      setTimeout(() => {
        this.confirmed = false;
      }, 2000);
    }
  }

  public changeOption(option: OptionValue): void {
    this.currentSelectedOption = option;
    this.currentDisplayedOptions = this.confirmOptionsList.filter(op => op.id !== option.id);
    this.updateIconConfigurations();
  }

  private cleanup(): void {
    window.removeEventListener('keypress', this.onKeyPress);
  }

  private onKeyPress = (event: any) => {
    if (event.key === Key.Enter && this.valid && document.activeElement.tagName.toUpperCase() !== 'TEXTAREA') {
      this.confirm(event);
      this.cleanup();
    }
  };

  private updateIconConfigurations(): void {
    this.cancelIconConfig = DialogFooter.toIconConfig('cancel-m', this.cancelBtnText);

    if (this.currentSelectedOption && this.confirmIconConfig) {
      console.warn('DialogFooter: You have both specified confirmIconOptions and a confirmIconConfig so the confirmIconConfig will be overwritten always');
    }

    if (this.currentSelectedOption) {
      this.internalConfirmIcon = DialogFooter.toIconConfig(this.currentSelectedOption.confirmIconName, this.currentSelectedOption.text);
    } else {
      this.internalConfirmIcon = this.confirmIconConfig || DialogFooter.toIconConfig('check-m', this.confirmBtnText);
    }
  }

  private static toIconConfig(icon: string, text: string): IconConfiguration {
    return IconConfiguration.medium(icon, '')
                            .withColor('white')
                            .withColorVariant(ICON_COLOR_VARIANT.light)
                            .withText(text);
  }
}

export type FooterButtonPosition = 'stretch' | 'centered';
