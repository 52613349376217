import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumDirectivesModule } from '@celum/ng2base';

import { CelumIconModule } from '../icon';
import { CelumAvatar } from './component/avatar';

@NgModule({
  imports: [
    // basic angular modules
    CommonModule,
    TranslateModule,
    // needed material modules
    MatIconModule,
    MatTooltipModule,
    // our own modules
    CelumDirectivesModule,
    CelumIconModule
  ],
  declarations: [CelumAvatar],
  exports: [CelumAvatar, TranslateModule],
  providers: []
})
export class CelumAvatarModule {}
