export enum Language {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr'
}

export const availableLanguages = [
  { option: Language.ENGLISH, label: 'LANGUAGE.EN' },
  { option: Language.GERMAN, label: 'LANGUAGE.DE' },
  { option: Language.FRENCH, label: 'LANGUAGE.FR' }
];
