<ng-container *ngIf="service.vm$ | async as vm">
  <div class="dashboard_top"></div>
  <div class="dashboard_new-library">
    <button class="dashboard_new-button" data-test-dashboard-new-library mat-button (click)="addNew()">
      <celum-icon [configuration]="createIcon"></celum-icon>
      <span class="dashboard_new-button_text">{{ 'COMMON.NEW' | translate | uppercase }}</span>
    </button>

    <libs-dashboard-info-header
      class="dashboard_counter"
      [numberOfFilteredItems]="vm.totalNumberOfOwnLibraries"
      [totalNumberOfItems]="vm.totalNumberOfLibraries"
      [appliedFilters]="vm.filters"
      (filterChanged)="applyFilter($event)"></libs-dashboard-info-header>
  </div>

  <div class="dashboard_banners">
    <libs-storage-limit-message-box></libs-storage-limit-message-box>
  </div>

  <libs-library-list class="dashboard_library-list celum-scrollbar" [filters]="vm.filters"></libs-library-list>
</ng-container>
