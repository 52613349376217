import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { SafeStyle } from '@angular/platform-browser';
import { Observable, zip } from 'rxjs';
import { take } from 'rxjs/operators';

import { CelumDialogOpener, PageContext } from '@celum/common-components';
import { OperationDefinition } from '@celum/core';

import { MagicButtonPage, MagicButtonPageConfiguration } from '../../page/magic-button-page';
import { MagicButtonService } from '../services/magic-button.service';

@Component({
             selector: 'celum-magic-button-component',
             templateUrl: './magic-button-component.html',
             styles: [],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class MagicButtonComponent {

  @Input() public buttonColor: SafeStyle;
  @Input() public disabledColor: SafeStyle;
  @Input() public blurContainerSelector: string;
  @Input() public disableButtonIfNoOperations = false;
  @Input() public pageTitleText: string;

  public operations$: Observable<OperationDefinition[]>;
  public featuredOperations$: Observable<OperationDefinition[]>;
  public dataContext$: Observable<PageContext>;

  constructor(service: MagicButtonService, private dialogOpener: CelumDialogOpener) {
    this.operations$ = service.operations$;
    this.featuredOperations$ = service.featuredOperations$;
    this.dataContext$ = service.dataContext$;
  }

  public openPage(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['magic-button-page_container', 'fullscreen'];

    zip(this.operations$, this.dataContext$).pipe(
      take(1)
    ).subscribe(([operations, dataContext]) => {
      const config = new MagicButtonPageConfiguration(this.buttonColor.toString(), operations, dataContext, this.blurContainerSelector, this.pageTitleText);
      this.dialogOpener.showDialog('magic-button-page', MagicButtonPage, config, dialogConfig);
    });
  }
}
