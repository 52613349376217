import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

import { UserDetails, UserService } from '@celum/authentication';
import { addScript, CelumPropertiesProvider, DataUtil, isTruthy } from '@celum/core';

export interface UserPilotInfo {
  uiLocale?: string;
  version?: string;
  userCreationDate?: string; // todo bst: get information (sacc)
}

@Injectable({ providedIn: 'root' })
export class UserPilot<T extends UserPilotInfo> {
  private user: UserDetails;

  constructor(
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  /** the user pilot object on the window changes, so we need to retrieve it newly for every call, saving it in a reusable variable once does not work  */
  private get userPilot(): UserPilotOnWindow {
    return (window as any).userpilot;
  }

  /**
   * This checks the relevant property and includes the user-pilot script if necessary.
   * It will reload on navigation end events as described in https://docs.userpilot.com/article/59-installation-guide-for-single-page-applications-spas
   */
  public initializeIfConfigured(): void {
    const userPilotApiKey = CelumPropertiesProvider.properties.userPilot?.apiKey;

    if (!DataUtil.isEmpty(userPilotApiKey)) {
      addScript(`https://js.userpilot.io/${userPilotApiKey}/latest.js`, { defer: true }).onload = () => {
        this.userService.currentUser$.pipe(isTruthy(), take(1)).subscribe(user => {
          this.user = user;
          this.identifyUserInitially();
          this.updateOnNavigation();
          this.updateOnLocaleChange();
        });
      };
    }
  }

  /**
   * Update specific user information
   * @param info object with all user pilot properties that should be updated
   */
  public updateUserInfo(info: Partial<T>): void {
    if (this.user) {
      this.userPilot.identify(this.user.oid, { ...info }); // user pilot mutates given object, so only send copy
    }
  }

  private identifyUserInitially(): void {
    this.userPilot.identify(this.user.oid, { version: CelumPropertiesProvider.properties.version, uiLocale: this.translateService.currentLang });
  }

  private updateOnLocaleChange(): void {
    this.translateService.onLangChange.subscribe(change => this.user && this.userPilot.identify(this.user.oid, { uiLocale: change.lang }));
  }

  private updateOnNavigation(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.userPilot.reload());
  }
}

type UserPilotOnWindow = {
  identify: (id: string, info: UserPilotInfo) => void;
  reload: (url?: string) => void;
};
