import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, HostBinding, OnDestroy, OnInit, Type, ViewChild,
  ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';

import { RemoveSnackbar, SnackbarComponent, SnackbarRegistry } from '@celum/common-components';
import { ReactiveComponent } from '@celum/ng2base';

import { getSnackbars } from '../store/snackbar-state';

@Component({
             selector: 'snackbar-list',
             template: `
               <ng-container #snackbarList></ng-container>`,
             styleUrls: ['./snackbar-list.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class SnackbarList extends ReactiveComponent implements OnInit, OnDestroy {

  public snackbars: { [key: string]: ComponentRef<SnackbarComponent<any>> } = {};

  @HostBinding('class.snackbar-list') public hostCls = true;

  @ViewChild('snackbarList', {
    read: ViewContainerRef,
    static: false
  }) private snackbarListContainerRef: ViewContainerRef;

  constructor(private changeDetector: ChangeDetectorRef, private store: Store<any>) {
    super();
  }

  public ngOnInit(): void {
    this.store.pipe(
      select(getSnackbars),
      takeUntil(this.unsubscribe$)
    ).subscribe(snackbars => {
      const added = snackbars.filter(snackbar => !this.snackbars[snackbar.id]);
      const modified = snackbars.filter(snackbar => this.snackbars[snackbar.id]);
      const removed = Object.keys(this.snackbars).filter(id => !snackbars.find(snackbar => snackbar.id === id));

      added.forEach(snackbar => this.createSnackbar(snackbar.id, SnackbarRegistry.getComponent(snackbar.id), { ...snackbar.config }, snackbar.placeOnTop));
      modified.forEach(snackbar => this.snackbars[snackbar.id].instance.configure({ ...snackbar.config }));
      removed.forEach(id => this.destroySnackbar(id));
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  private createSnackbar(id: string, component: Type<SnackbarComponent<any>>, config: any, placeOnTop: boolean): void {
    if (!component) {
      console.error(`No component registered for snackbar with id ${id}`);
      return;
    }

    const index = placeOnTop ? 0 : this.snackbarListContainerRef.length;

    const componentRef: ComponentRef<SnackbarComponent<any>> = this.snackbarListContainerRef.createComponent(component, {index});
    this.snackbars[id] = componentRef;

    componentRef.instance.configure({ ...config }); // as this comes from the store and is not allowed to be modified... make sure that actual snackbar cannot
                                                    // produce errors
    componentRef.instance.dismiss.pipe(take(1)).subscribe(() => this.store.next(new RemoveSnackbar(id)));

    this.changeDetector.markForCheck();
  }

  private destroySnackbar(id: string): void {
    const componentRef = this.snackbars[id];
    if (componentRef) {
      delete this.snackbars[id];
      componentRef.destroy();
    }
  }
}
