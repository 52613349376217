import { Type } from '@angular/core';
import { Action } from '@ngrx/store';

import { SnackbarComponent } from './snackbar-component';

export enum SnackbarActionTypes {
  RemoveSnackbar = '[SnackbarAction] Remove snackbar',
  ShowSnackbar = '[SnackbarAction] Show snackbar'
}

export class RemoveSnackbar implements Action {
  public readonly type = SnackbarActionTypes.RemoveSnackbar;

  constructor(public id: string) {
    SnackbarRegistry.unregister(id);
  }
}

export class ShowSnackbar<C> implements Action {
  public readonly type = SnackbarActionTypes.ShowSnackbar;

  /**
   * Action to show or update a snackbar. If snackbar with id already exists, it will only call .configure with the new config
   * @param id of snackbar
   * @param component type used to dynamically create instance of that component
   * @param config which will be passed to the component
   * @param placeOnTop whether the snackbar should be placed on top of the others or at the bottom
   */
  constructor(public id: string, component: Type<SnackbarComponent<C>>, public config: C, public placeOnTop: boolean = true) {
    SnackbarRegistry.registerComponent(id, component);
  }
}

export type SnackbarActions = RemoveSnackbar | ShowSnackbar<any>;

/**
 * Everything that is passed via actions is sealed/freezed by default. So if you pass a class definition, this whole class is sealed and you cannot add any
 * properties anymore. This is an issue if the class in question is used to dynamically generate an angular component. Angular tries to add __NG_ELEMENT_ID__
 * property to the class which will fail. In order to be able to set strictActionImmutability to "true" (which is the default), we cannot store the component
 * type in the store anymore. Instead, there is a static registry where the component types are stored (by passed id) and the snackbar-list grabs the component
 * from there to create the angular component out of it.
 */
class SnackbarRegistry {

  private static components = new Map<string, Type<SnackbarComponent<any>>>();

  public static registerComponent(id: string, component: Type<SnackbarComponent<any>>): void {
    this.components.set(id, component);
  }

  public static unregister(id: string): void {
    this.components.delete(id);
  }

  public static getComponent(id: string): Type<SnackbarComponent<any>> {
    return this.components.get(id);
  }
}

/**
 * Why is this necessary?
 *
 * Webpack creates a scope for each bundle it creates. All code you create only exists (and is accessible) inside of this scope. This also applies to classes
 * and their static properties/functions. For example, consider extensions for Nova. If you have extensions loaded that do NOT share their dependencies with
 * Nova, they will provide their own version of all classes and functions. These classes and functions only exist inside the scope of the extension (or the
 * extensions if some of them share dependencies). Even static classes. This proxy allows to make sure that static classes are actually behaving like you would
 * expect it (and as it would be if it wouldn't be for webpack) regardless of how many different webpack bundles are loaded on the side.
 */
let reg = SnackbarRegistry;

if (globalThis) {
  if (!(globalThis as any).CelumSnackbarRegistry) {
    (globalThis as any).CelumSnackbarRegistry = SnackbarRegistry;
  } else {
    reg = (globalThis as any).CelumSnackbarRegistry;
  }
}

export { reg as SnackbarRegistry };
