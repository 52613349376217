import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ClickTapDirective, LongPressDirective } from '@celum/ng2base';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from '@celum/ngx-perfect-scrollbar';


@Component({
             selector: 'celum-list-infinite-scroll-wrapper',
             templateUrl: './infinite-scroll-list-wrapper.component.html',
             styleUrls: ['./infinite-scroll-list-wrapper.component.less'],
             standalone: true,
             imports: [CommonModule, InfiniteScrollModule, PerfectScrollbarModule, ClickTapDirective, LongPressDirective],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class InfiniteScrollListWrapperComponent {

  @Input() public fromRoot: boolean;
  @Input() public scrollbarConfig: PerfectScrollbarConfigInterface;

  @Output() public readonly onEmptySpaceClick = new EventEmitter<Event>();
  @Output() public readonly onContextMenu = new EventEmitter<MouseEvent>();
  @Output() public readonly onScroll = new EventEmitter<boolean>();

}
