import { CelumPropertiesProvider } from '@celum/core';

export interface CelumExperienceProperties {
  apiUrl: string;
  pusher: {
    appKey: string;
    authUrl: string;
    enableLogging?: boolean;
  };
  insightsFeatureEnabled?: boolean;
  experienceAdministrationFeatureEnabled?: boolean;
  uploadPortalBgImagesEnabled?: boolean;
}

export class ExperienceProperties {
  static get properties(): CelumExperienceProperties {
    const testingFallback = {
      apiUrl: `test-api`,
      pusher: { appKey: 'test-key', authUrl: 'test-url' }
    };
    return CelumPropertiesProvider.properties.appProperties?.experience ?? testingFallback;
  }
}
