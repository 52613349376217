import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { distinctUntilKeyChanged, map, Observable, switchMap, take, tap, throwError } from 'rxjs';

import { UserService } from '@celum/authentication';
import { AtLeastOne, CelumPropertiesProvider, IPaginationResult, isTruthy } from '@celum/core';
import { EntityResolver, ResolverCallConfig, Result, schemaOf } from '@celum/ng2base';
import { AccountUser, accountUserTranslator, ACCOUNT_USER, PaginationOption, ResourceService } from '@celum/shared/domain';

export type AccountUserSorter = {
  key: keyof Pick<AccountUser, 'firstName' | 'lastName' | 'email'>;
  order: 'desc' | 'asc';
};

export interface AccountUserFindOptions extends PaginationOption {
  ids: string[];
  filter: string;
  sortBy?: AccountUserSorter;
  /**
   * If set to true, the returned stream will not complete and emit again in case the user selects a different account.
   * This is mainly used in SACC as it is the only place where you can change accounts without reloading the app.
   * Default: false
   */
  keepStreamAlive: boolean;
  continuationToken?: string;
}

export type AccountUserFindQueryParams = {
  size?: string;
  sort?: string;
  continuationToken?: string;
};

export interface IAccountContinuationPaginationResult extends IPaginationResult {
  /** Specifies the filter count of results regardless of what was already loaded. */
  filterCount?: number;
  /** Specifies the continuation token that can be used to load more results. */
  continuationToken?: string;
}

const operationNotSupported = 'Operation not supported';

type AccountServiceCallResult = { entities: any[]; filterCount?: number; totalCount: number; continuationToken: string };

@Injectable({ providedIn: 'root' })
export class AccountUserService implements ResourceService<AccountUser>, EntityResolver {
  constructor(
    private userService: UserService,
    private http: HttpClient
  ) {}

  public find(options?: AtLeastOne<AccountUserFindOptions>): Observable<Result<AccountUser, IAccountContinuationPaginationResult>> {
    return this.executeCall(options).pipe(map(result => AccountUserService.mapResult(result)));
  }

  public getMany(ids: string[]): ResolverCallConfig {
    const response$ = this.executeCall({ ids });

    return {
      response$: response$.pipe(map(result => result.entities.map(entity => ({ ...entity, typeKey: ACCOUNT_USER })))),
      metaInfo: {
        schema: [schemaOf(ACCOUNT_USER)]
      }
    };
  }

  public create(creator: any): Observable<AccountUser> {
    return throwError(() => new Error(operationNotSupported));
  }

  public delete(id: string): Observable<void> {
    return throwError(() => new Error(operationNotSupported));
  }

  public findOne(options: any): Observable<AccountUser> {
    return throwError(() => new Error(operationNotSupported));
  }

  public update(id: string, updater: any): Observable<AccountUser> {
    return throwError(() => new Error(operationNotSupported));
  }

  private executeCall(options?: AtLeastOne<AccountUserFindOptions>): Observable<AccountServiceCallResult> {
    const body = this.getFindBody(options);

    const params = this.getFindQueryParams(options);

    return this.userService.accountAccessForCurrentTenant$.pipe(
      isTruthy(),
      options?.keepStreamAlive ? tap() : take(1),
      distinctUntilKeyChanged('accountId'),
      map(tenant => tenant?.accountId),
      switchMap(accountId =>
        this.http.post<AccountServiceCallResult>(`${CelumPropertiesProvider.properties.authentication.saccUrl}/accounts/${accountId}/users/search`, body, {
          params
        })
      )
    );
  }

  private getFindBody(options?: AtLeastOne<AccountUserFindOptions>): unknown {
    return options?.ids
      ? {
          type: 'oid',
          oidIn: options.ids
        }
      : { filter: options.filter };
  }

  private getFindQueryParams(options: AtLeastOne<AccountUserFindOptions>): AccountUserFindQueryParams {
    const params: AccountUserFindQueryParams = {};

    if (options?.limit) {
      params.size = options.limit.toString();
    }

    if (options?.sortBy) {
      params.sort = `${options.sortBy.key},${options.sortBy.order}`;
    }

    if (options?.continuationToken) {
      params.continuationToken = options.continuationToken;
    }

    return params;
  }

  private static mapResult(result: AccountServiceCallResult): Result<AccountUser, IAccountContinuationPaginationResult> {
    return {
      entities: result.entities.map((user: AccountUser) => accountUserTranslator(user)),
      paging: {
        hasBottom: !!result.continuationToken,
        hasTop: true,
        filterCount: result.filterCount,
        totalElementCount: result.totalCount,
        continuationToken: result.continuationToken
      }
    };
  }
}
