import { SafeHtml } from '@angular/platform-browser';

import { ICON_SIZE } from '@celum/core';

export enum ICON_COLOR_VARIANT {
  dark = 0, light = 1
}

export enum ICON_TEXT_ALIGN {
  right = 0, left = 1, top = 2, bottom = 3
}

export enum ICON_TEXT_TRANSFORM {
  uppercase, lowercase, none
}

export const DEFAULT_ICON_COLOR_VARIANT: ICON_COLOR_VARIANT = ICON_COLOR_VARIANT.dark;

export const DARK_COLOR = '#3d4043';
export const LIGHT_COLOR = '#ffffff';

export const ICON_TEXT_TRANSFORM_NONE = 'none';
export const ICON_TEXT_TRANSFORM_UPPERCASE = 'uppercase';
export const ICON_TEXT_TRANSFORM_LOWERCASE = 'lowercase';

export const DEFAULT_OUTLINE_COLOR = 'rgba(255, 255, 255, 0.8)';
export const DEFAULT_RIPPLE_COLOR = '#ffffff18';

export class IconConfiguration {
  public icon: string;
  public iconWidth: number | ICON_SIZE = ICON_SIZE.l;
  public iconHeight: number | ICON_SIZE = ICON_SIZE.l;
  public title = '';
  public text = '';
  public textAlign: ICON_TEXT_ALIGN;
  public textSize = 13;
  public colorVariant: ICON_COLOR_VARIANT = DEFAULT_ICON_COLOR_VARIANT;
  public color: string;
  public hoverColor: string;
  public textColor: string;
  public textHoverColor: string;
  public safeHtml: SafeHtml;
  public textTransform: string = ICON_TEXT_TRANSFORM_UPPERCASE;
  public cssCls: string;
  public customStyles: any;

  public backgroundColor: string;
  public outlineColor: string;
  public outlineSize = 0.1;

  constructor(icon: string) {
    this.icon = icon;
  }

  public withText(iconText: string): IconConfiguration {
    this.text = iconText;
    return this;
  }

  public withSafeHtmlText(iconText: SafeHtml): IconConfiguration {
    this.safeHtml = iconText;
    return this;
  }

  public withTextTransformation(textTransform: string): IconConfiguration {
    this.textTransform = textTransform;
    return this;
  }

  public withTitle(iconTitle: string): IconConfiguration {
    this.title = iconTitle;
    return this;
  }

  public withTextColor(color: string): IconConfiguration {
    this.textColor = color;
    return this;
  }

  public withIconSize(size: number | ICON_SIZE): IconConfiguration {
    this.iconHeight = size;
    this.iconWidth = size;
    return this;
  }

  public withCssClass(cls: string): IconConfiguration {
    this.cssCls = cls;
    return this;
  }

  public withIconDimensions(width: number, height: number): IconConfiguration {
    this.iconWidth = width;
    this.iconHeight = height;
    return this;
  }

  public withColorVariant(colorVariant: ICON_COLOR_VARIANT): IconConfiguration {
    this.colorVariant = colorVariant;
    return this;
  }

  public withColor(color: string): IconConfiguration {
    this.color = color;
    return this;
  }

  public withHoverColor(color: string): IconConfiguration {
    this.hoverColor = color;
    return this;
  }

  public withTextHoverColor(color: string): IconConfiguration {
    this.textHoverColor = color;
    return this;
  }

  public withTextAlign(align: ICON_TEXT_ALIGN): IconConfiguration {
    this.textAlign = align;
    return this;
  }

  public withTextSize(size: number): IconConfiguration {
    this.textSize = size;
    return this;
  }

  public withBackground(backgroundColor: string, outline: boolean = true, outlineSize?: number, outlineColor?: string): IconConfiguration {
    if (outline) {
      this.outlineColor = outlineColor || DEFAULT_OUTLINE_COLOR;
      if (outlineSize) {
        this.outlineSize = outlineSize;
      }
    }

    this.backgroundColor = backgroundColor;
    return this;
  }

  public withCustomStyles(customStyles: any): IconConfiguration {
    this.customStyles = customStyles;
    return this;
  }

  public static xSmall(icon: string, title?: string): IconConfiguration {
    const iconConfiguration = new IconConfiguration(icon).withIconSize(ICON_SIZE.xs);

    if (title) {
      iconConfiguration.withTitle(title);
    }

    return iconConfiguration;
  }

  public static small(icon: string, title?: string): IconConfiguration {
    const iconConfiguration = new IconConfiguration(icon).withIconSize(ICON_SIZE.s);

    if (title) {
      iconConfiguration.withTitle(title);
    }

    return iconConfiguration;
  }

  public static smallDark(icon: string, title?: string): IconConfiguration {
    return IconConfiguration.small(icon, title).withColorVariant(ICON_COLOR_VARIANT.dark);
  }

  public static smallDarkRight(icon: string, text: string, title: string): IconConfiguration {
    return IconConfiguration.smallDark(icon, title).withText(text).withTextAlign(ICON_TEXT_ALIGN.right);
  }

  public static medium(icon: string, title?: string): IconConfiguration {
    const iconConfiguration = new IconConfiguration(icon).withIconSize(ICON_SIZE.m);

    if (title) {
      iconConfiguration.withTitle(title);
    }

    return iconConfiguration;
  }

  public static mediumDark(icon: string, title?: string): IconConfiguration {
    return IconConfiguration.medium(icon, title).withColorVariant(ICON_COLOR_VARIANT.dark);
  }

  public static mediumDarkRight(icon: string, text: string, title?: string): IconConfiguration {
    return IconConfiguration.mediumDark(icon, title).withText(text).withTextAlign(ICON_TEXT_ALIGN.right);
  }

  public static large(icon: string, title?: string): IconConfiguration {
    const iconConfiguration = new IconConfiguration(icon).withIconSize(ICON_SIZE.l);

    if (title) {
      iconConfiguration.withTitle(title);
    }

    return iconConfiguration;
  }

  public static largeDark(icon: string, title?: string): IconConfiguration {
    return IconConfiguration.large(icon, title).withColorVariant(ICON_COLOR_VARIANT.dark);
  }

  public static largeDarkRight(icon: string, text: string, title: string): IconConfiguration {
    return IconConfiguration.largeDark(icon, title).withText(text).withTextAlign(ICON_TEXT_ALIGN.right);
  }

  public static xLarge(icon: string, title: string, size: number): IconConfiguration {
    return new IconConfiguration(icon).withTitle(title).withIconSize(size);
  }

  public static xLargeDarkRight(icon: string, text: string, title: string, size: number): IconConfiguration {
    return IconConfiguration.xLarge(icon, title, size).withText(text).withTextAlign(ICON_TEXT_ALIGN.right).withColorVariant(ICON_COLOR_VARIANT.dark);
  }

  public static xLargeLightRight(icon: string, text: string, title: string, size: number): IconConfiguration {
    return IconConfiguration.xLarge(icon, title, size).withText(text).withTextAlign(ICON_TEXT_ALIGN.right).withColorVariant(ICON_COLOR_VARIANT.light);
  }

  public static clone(config: IconConfiguration): IconConfiguration {
    const newConfig = new IconConfiguration(config.icon);

    Object.assign(newConfig, config);

    return newConfig;
  }
}
