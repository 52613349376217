import { Inject, Injectable, InjectionToken, OnDestroy, Optional, Provider } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';

import { DataUtil, isArrayEqual } from '@celum/core';

import { AbstractList } from '../components/abstract-list';
import { SelectionBehavior } from '../model/list-selection-handler.model';

export const LIST_SELECTION_CONFIG = new InjectionToken('LIST_SELECTION_CONFIG');

export type ListSelectionConfiguration = {
  /** Configure whether the selection is updated if items change. By default, the selection is updated. */
  updateSelectionOnItemsChange?: boolean;
  selectionBehaviour?: SelectionBehavior;
  selectionLimit?: number;
};

type SelectionState<E> = {
  // shift selection is determined by this item, which is determined by the last click or ctrl click
  selectionAnchorItem: E;
  selection: E[];
  items: E[];
  selectionBehavior: SelectionBehavior;
  deselectOnClick: boolean;
  selectionLimit?: number;
};

@Injectable()
export class ListSelectionHandler<E> extends ComponentStore<SelectionState<E>> implements OnDestroy {
  public selectionChanged$: Observable<E[]>;
  public selection$: Observable<E[]> = this.select(state => state.selection);
  public selectionCleared$: Observable<void>;
  public updateSelectionOnItemsChange: boolean;

  private connectedList: AbstractList<E>;
  private selectionChangedSubj = new Subject<E[]>();
  private selectionClearedSubj = new Subject<void>();

  constructor(@Optional() @Inject(LIST_SELECTION_CONFIG) private config?: ListSelectionConfiguration) {
    super({
      selection: [],
      items: [],
      selectionAnchorItem: null,
      selectionBehavior: config?.selectionBehaviour || SelectionBehavior.SINGLE,
      deselectOnClick: true
    });
    this.selectionChanged$ = this.selectionChangedSubj.asObservable();
    this.selectionCleared$ = this.selectionClearedSubj.asObservable();

    this.updateSelectionOnItemsChange = this.config?.updateSelectionOnItemsChange ?? true;
  }

  // tslint:disable-next-line:use-lifecycle-interface
  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.selectionChangedSubj.complete();
    this.selectionClearedSubj.complete();
  }

  public setCompareFn(compare: (a: E, b: E) => boolean): void {
    this.compareFn = compare;
  }

  public setConnectedList(connectedList: AbstractList<E>): void {
    this.connectedList = connectedList;
  }

  public setSelectionBehavior(selectionBehavior: SelectionBehavior): void {
    this.patchState({ selectionBehavior });
  }

  public setSelectionLimit(selectionLimit: number): void {
    this.patchState({ selectionLimit });
  }

  public setItems(items: E[]): void {
    this.patchState({ items });

    if (!this.updateSelectionOnItemsChange) {
      return;
    }

    const { selection } = this.get();

    if (!DataUtil.isEmpty(items) && !DataUtil.isEmpty(selection)) {
      const newSelection = selection.filter(selectedItem => items.find(existingItem => this.compareFn(existingItem, selectedItem)));

      const hasChanges = !isArrayEqual(selection, newSelection, { customEqualityCheck: this.compareFn });

      if (!hasChanges) {
        return;
      }

      this.patchState({ selection: newSelection });
      this.selectionChangedSubj.next([...newSelection]);
    } else if (DataUtil.isEmpty(items) && !DataUtil.isEmpty(selection)) {
      this.patchState({ selection: [] });
      this.selectionChangedSubj.next([]);
    }
  }

  public isInSelection = (item: E) => !!this.get().selection.find(selected => this.compareFn(selected, item));

  public isDisabled = (item: E) => {
    const { selectionBehavior, selectionLimit, selection } = this.get();

    if (selectionBehavior === SelectionBehavior.MULTI_TOGGLE_LIMITED) {
      return selection.length >= selectionLimit && !this.isInSelection(item);
    } else {
      return false;
    }
  };

  /** Try to select items by id. Only works if the passed `idKey` actually exists on the items. Otherwise nothing will happen. */
  public selectById(ids: string[], scrollIntoView: boolean, silent: boolean, idKey: string = 'id'): boolean {
    const newSelection: E[] = [];
    let somethingSelected = false;

    ids.forEach(id => {
      const item = this.get().items.find(existingItem => (existingItem as any)[idKey] === id);

      if (item) {
        newSelection.push(item);
        somethingSelected = true;
      }
    });

    this.patchState({ selection: newSelection });

    if (!silent) {
      this.selectionChangedSubj.next([...newSelection]);
    }

    if (somethingSelected && scrollIntoView) {
      this.connectedList.scrollIntoView(ids);
    }

    return somethingSelected;
  }

  public clearSelection(silent: boolean = false): void {
    this.patchState({ selection: [], selectionAnchorItem: null });

    if (!silent) {
      this.selectionChangedSubj.next([]);
    }

    this.selectionClearedSubj.next();
  }

  public selectAll(silent: boolean = false): void {
    this.setSelection(this.get().items, silent);
  }

  public setSelection(newSelection: E[], silent: boolean = false): void {
    if (!newSelection) {
      return;
    }

    const currentSelection = this.get().selection;

    if (isArrayEqual(currentSelection, newSelection, { customEqualityCheck: this.compareFn })) {
      return;
    }

    this.patchState({ selection: newSelection });

    if (!newSelection.includes(this.get().selectionAnchorItem) && newSelection.length) {
      this.patchState({ selectionAnchorItem: newSelection[0] });
    }

    if (!silent) {
      this.selectionChangedSubj.next([...newSelection]);
    }
  }

  public getCurrentSelection(): E[] {
    return this.get().selection;
  }

  public getSelectionBehavior(): SelectionBehavior {
    return this.get().selectionBehavior;
  }

  public isActiveMultiSelection(): boolean {
    return this.get().selection.length > 1;
  }

  public handleItemClicked(event: MouseEvent, item: E): void {
    const { selection, selectionBehavior } = this.get();

    if (this.isDisabled(item)) {
      return;
    }

    if (selectionBehavior === SelectionBehavior.SINGLE) {
      !this.isInSelection(item) && this.setSelection([item]);
    } else if (selectionBehavior === SelectionBehavior.MULTI_TOGGLE || selectionBehavior === SelectionBehavior.MULTI_TOGGLE_LIMITED) {
      event.shiftKey ? this.doShiftSelection(item) : this.toggleSelection(item);
    } else {
      if (ListSelectionHandler.isMultiSelectionChangeForSingleItem(selection, event)) {
        this.toggleSelection(item);
      } else if (ListSelectionHandler.isMultiSelectionChangeWithShift(selection, event)) {
        this.doShiftSelection(item);
      } else if (selection.length > 1 || !this.isInSelection(item)) {
        this.setSelection([item]);
      }
    }
  }

  private compareFn: (a: E, b: E) => boolean = (a, b) => a === b;

  private toggleSelection(item: E): void {
    const selection = this.get().selection;

    let matchedItem: E;

    if (!DataUtil.isEmpty(selection)) {
      matchedItem = selection.find(selected => this.compareFn(selected, item));
    }

    if (!matchedItem) {
      // item not yet in the list, add it
      this.patchState({ selection: [...(selection ?? []), item] });
    } else {
      // item already in the list, remove it
      this.patchState({ selection: selection.filter(selectedItem => selectedItem !== matchedItem) });
    }

    this.selectionChangedSubj.next([...this.get().selection]);

    this.patchState({ selectionAnchorItem: item });
  }

  private doShiftSelection(newItem: E): void {
    const { selectionAnchorItem, items } = this.get();
    const activeItemIndex = items.findIndex(item => this.compareFn(item, selectionAnchorItem));
    const itemIndex = items.findIndex(item => this.compareFn(item, newItem));

    if (!selectionAnchorItem) {
      this.patchState({ selection: [newItem] });
      this.selectionChangedSubj.next([newItem]);
      return;
    }

    const up = itemIndex < activeItemIndex;

    this.patchState({ selection: up ? items.slice(itemIndex, activeItemIndex + 1) : items.slice(activeItemIndex, itemIndex + 1) });
    this.selectionChangedSubj.next([...this.get().selection]);
  }

  public static withConfiguration(config: ListSelectionConfiguration): Provider[] {
    return [
      {
        provide: LIST_SELECTION_CONFIG,
        useValue: config
      },
      ListSelectionHandler
    ];
  }

  private static isActiveMultiSelection<E>(selection: E[]): boolean {
    return selection.length > 0;
  }

  private static isMultiSelectionChangeWithShift<E>(selection: E[], event: MouseEvent): boolean {
    return this.isActiveMultiSelection(selection) && event.shiftKey;
  }

  private static isMultiSelectionChangeForSingleItem<E>(selection: E[], event: MouseEvent): boolean {
    return this.isActiveMultiSelection(selection) && (event.ctrlKey || event.metaKey);
  }
}
