<div class="message-box_background"></div>
<div class="message-box_content" [class.message-box_content--centered]="centered">
  <div class="message-box_icon">
    <div #ref>
      <ng-content select="[messageIcon]"></ng-content>
    </div>
    <celum-icon *ngIf="ref.children.length == 0" [configuration]="internalIcon"></celum-icon>
  </div>
  <div class="message-box_text">
    <span [innerHTML]="text ? (text | translate | sanitized) : ''"></span>
    <ng-content select="[messageText]"></ng-content>
  </div>
</div>
