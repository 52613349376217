import { CHRepository } from './user.model';

export interface LicenseDto {
  type: string;
  active: boolean;
  edition?: string;
  monthlyUploadLimit?: number;
  chRepositories?: CHRepository[];
  tenantAwareUrl?: string;
  url?: string;
  experienceTenantAwareUrl?: string;
  experienceUrl?: string;
  librariesTenantAwareUrl?: string;
  librariesUrl?: string;
  wrTenantAwareUrl?: string;
  wrUrl?: string;
  creationLimit?: number;
  storageLimit?: number;
}

export enum LicenseType {
  CONTENT_HUB = 'ContentHubLicense',
  WORK = 'WorkroomsLicense',
  DRIVE = 'DriveLicense',
  EXPERIENCE = 'ExperienceLicense',
  LIBRARIES = 'ExperienceLicense',
  X3D = 'X3DLicense',
  NO_LICENSE = 'NoLicense'
}

export type BaseLicense = {
  type: LicenseType;
  active: boolean;
};

export type X3DLicense = BaseLicense & {
  monthlyUploadLimit: number;
};

export type ContentHubLicense = BaseLicense & {
  chRepositories: CHRepository[];
};

export type WorkroomsLicense = BaseLicense & {
  creationLimit: number;
  storageLimit: number;
  wrUrl: string;
  wrTenantAwareUrl: string;
};

export type DriveLicense = BaseLicense & {
  edition: string;
};

export type ExperienceLicense = BaseLicense & {
  experienceUrl: string;
  experienceTenantAwareUrl: string;
  librariesUrl: string;
  librariesTenantAwareUrl: string;
};

export type License = ContentHubLicense | WorkroomsLicense | DriveLicense | ExperienceLicense | X3DLicense | BaseLicense;

export const isContentHubLicense = (license: License): license is ContentHubLicense => license.type === LicenseType.CONTENT_HUB;
export const isWorkroomsLicense = (license: License): license is WorkroomsLicense => license.type === LicenseType.WORK;
export const isDriveLicense = (license: License): license is DriveLicense => license.type === LicenseType.DRIVE;
export const isExperienceLicense = (license: License): license is ExperienceLicense => license.type === LicenseType.EXPERIENCE;
export const isX3DLicense = (license: License): license is X3DLicense => license.type === LicenseType.X3D;
