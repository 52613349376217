<ng-container *ngIf="libraryListService.vm$ | async as vm">
  <mat-spinner *ngIf="vm.loading" class="libs-library-list_loading" [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>

  <celum-simple-list
    *ngIf="!vm.loading && !vm.showEmptyPage"
    class="libs-library-list_list"
    [hasMoreBottom]="vm.hasBottom"
    [items]="vm.libraries | async"
    [itemTemplate]="libraryTemplate"
    [scrollEngine]="'native'"
    (requestNextPage)="libraryListService.loadNextBatch()">
    <ng-template let-item #libraryTemplate>
      <libs-library-list-row
        [attr.data-test-library-list-row]="item.id"
        [library]="item"
        (changeStatusOfLibrary)="changeStateOfLibrary(item, $event)"
        (deleteLibrary)="deleteLibrary(item)"
        (editLibrary)="editLibrary(item)"
        (openJSON)="openLibraryJSON(item)"
        (previewLibrary)="openLibraryPreview(item)"></libs-library-list-row>
    </ng-template>
  </celum-simple-list>

  <empty-page *ngIf="vm.showEmptyPage" class="libs-library-list_empty" [config]="noResultsConfig"></empty-page>
</ng-container>
