/* tslint:disable:no-empty */
import { inject, InjectionToken, Provider } from '@angular/core';

import { CelumPropertiesProvider } from '@celum/core';

export interface TrackingService {
  /**
   * Initializes the tracking service underneath.
   * Before calling this method, nothing should be tracked.
   * One reason to force the application to inject the service and call this method is that the service is guaranteed to be initialized,
   * even if it's not used directly. This is often the case if you only want to track network/page-load/etc.
   */
  init(): void;

  /* Report an error to the service.*/
  trackError(error: Error): void;

  /* Report a custom event to the service. In addition to the name you can provide arbitrary properties.*/
  trackEvent(name: string, properties?: Record<string, any>): void;

  // Add global custom property, which will be sent with every event
  addGlobalCustomProperty(name: string, value: string): void;

  // Delete global custom property
  deleteGlobalCustomProperty(name: string): void;
}

/**
 * Use this token to inject the {@link TrackingService}.
 * The token must be provided by your application, see {@link provideApplicationInsights} for more details on how to set it up.
 */
export const TRACKING_SERVICE = new InjectionToken<TrackingService>('TrackingService');

/**
 * This service can be used as a fallback to a tracking service if none should be used.
 * The advantage of providing a noop service instead of not providing a service at all is that usages can rely on a tracking service being there at all times.
 * It can also be used for testing purposes.
 */
export class NoopTrackingService implements TrackingService {
  public init(): void {}
  public trackError(): void {}
  public trackEvent(): void {}
  public addGlobalCustomProperty(name: string, value: string): void {}
  public deleteGlobalCustomProperty(name: string): void {}
}

/**
 * Use this provider to add azure application insights to your application.
 * In order for this to work, you need to call {@link prepareApplicationInsights} before bootstrapping your application.
 * Additionally inject the service and call the `init` method in your app component to activate the tracking.
 * In case that azure insights is not instrumented (properties are not set), a noop tracking service is provided instead and the insights bundle is not loaded.
 */
export function provideApplicationInsights(): Provider {
  return {
    provide: TRACKING_SERVICE,
    useFactory: () => {
      const insights = (window as any).applicationInsights;
      return insights ? inject(insights) : new NoopTrackingService();
    }
  };
}

/**
 * Use this function to conditionally load azure application insights if its instrumented.
 * This has to be done before bootstrapping your application.
 * Example:
 * ```
 * import { prepareApplicationInsights } from '@celum/shared/util/tracking';
 * prepareApplicationInsights()
 *    .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
 *    .catch(err => console.error(err));
 *
 * The reason why it has to be done before bootstrapping is because there is no other way of providing a service lazily otherwise.
 * We can't use APP_INITIALIZER because it depends on the ErrorHandler which already injects the TrackingService.
 */
export function prepareApplicationInsights(): Promise<any> {
  if (!Boolean(CelumPropertiesProvider.properties.applicationInsights?.instrumentationKey)) {
    return Promise.resolve();
  }

  return import('@celum/shared/util/tracking').then(m => m.ApplicationInsightsService).then(service => ((window as any).applicationInsights = service));
}

/** Common properties that can be added as global parameters or as metadata to specific events. */
export enum TrackingProperty {
  // Mandatory properties that should be set by all applications

  /** tenant context from within the event was triggered */
  TENANT_ID = 'tenantId',
  /** name of the service that the event belongs to, e.g. "portals", "experience", ... */
  SERVICE = 'service',
  /** oid of the user that triggered the event */
  USER_OID = 'userOid',

  // Optional properties

  /** name of the context that the event belongs to, e.g. "single-download", "multi-download" */
  CONTEXT = 'context',
  /** name of the page that the event belongs to, e.g. "portal-designer", "library-wizard", ... */
  PAGE = 'page'
}

/** Common events that can occur in multiple applications and are likely to be used for cross-application insights in the future. */
export enum TrackingEvents {
  /** used to track the initial page load of an application, if you want to track specific pages within your app, use {@see PAGE_NAVIGATION} */
  PAGE_LOAD = 'page-loaded',
  /** used for page navigations within an application */
  PAGE_NAVIGATION = 'page-navigation',
  /** used for a single asset download */
  ASSET_DOWNLOAD = 'asset-download',
  /** used for downloading all assets from a specific scope, can be used if no {@see ASSET_DOWNLOAD} events can be generated. */
  ASSET_DOWNLOAD_ALL = 'asset-download-all',
  /** used for viewing an asset separately */
  ASSET_VIEWED = 'asset-viewed'
}
